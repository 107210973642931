import { Box, Flex, Text, Progress, Icon } from "@chakra-ui/react";
import { BiSolidFilePdf } from "react-icons/bi";


interface GenerateExamIAModalProp {
    nombreFile: string
}

export const GenerateExamIAModal = ({ nombreFile }: GenerateExamIAModalProp) => {
    return (
        <Flex
            align="center"
            justify="center"
            direction="column"
            gap="20px"
        >
            <Box
                justifyItems="center"
                border="1px solid"
                borderColor="#EAEAEA"
                rounded="14px"
                p="80px"
            >
                <Flex
                    direction="column"
                    align="center"
                >
                    <Icon boxSize="80px" as={BiSolidFilePdf} />

                    <Text
                        fontSize="20px"
                        mb="20px"
                    >
                        {nombreFile}
                    </Text>
                </Flex>

                <Progress
                    size="xs"
                    isIndeterminate
                    w="80%"
                    mt="20px"
                />

                <Text
                    mt="20px"
                >
                    Generando preguntas IA, espere por favor
                </Text>
            </Box>
        </Flex>
    );
}