import { Button, Flex, Icon, Spinner, Switch, Text, Tooltip, useDisclosure, useToast } from "@chakra-ui/react"
import { useClient } from "../../../../shared/context/client.context"
import { useDataId } from "../../../../shared/hooks/useDataId"
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes"
import { useParams } from "react-router-dom"
import { Topbar } from "../../../../shared/components/Topbar/Topbar"
import { Puck, Render } from "@measured/puck";
import "@measured/puck/puck.css";
import { Heading } from "../../components/Editor/Heading/Heading"
import { renderToStaticMarkup } from 'react-dom/server';
import { EmailSection } from "../../components/Editor/Table/Section/Section"
import { EmailRow } from "../../components/Editor/Table/Row/Row"
import { EmailColumn } from "../../components/Editor/Table/Column/Column"
import { EmailTextBlock } from "../../components/Editor/Table/TextBlock/TextBlock"
import { EmailHeader } from "../../components/Editor/Table/Header/Header"
import { Imagen } from "../../components/Editor/Image/Image"
import { Button as ComponentButton } from "../../components/Editor/Table/Button/Button"
import { VerticalSpace } from "../../components/Editor/VerticalSpace/VerticalSpace"
import { Text as ComponentText } from "../../components/Editor/Text/Text"
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes"
import { toastNotify } from "../../../../shared/utils/functions/toastNotify"
import { updatePlantilla } from "../../../../shared/middlewares/plantillas.middleware"
import { handleErrors } from "../../../../shared/utils/functions/handleErrors"
import { EmailProgress } from "../../components/Editor/Table/Progreso/Progreso"
import { Condicionales } from "../../components/Editor/Table/Condicionales/Condicionales"
import { Container } from "../../components/Editor/Container/Container"
import { HiOutlineDocumentMagnifyingGlass } from "react-icons/hi2"
import { useAuthContex } from "../../../../shared/context/auth.context"
import { TestPlantillasModal } from "../../components/TestPlantillasModal"
import { Enlace } from "../../components/Editor/Table/Enlace/Enlace"
import { Bucle } from "../../components/Editor/Table/Bucle/Bucle"
import { formatTimezone } from "../../../../shared/utils/functions/parseDate"
import { useTimeZone } from "../../../../shared/hooks/useTimeZone"
import { useEffect, useState } from "react"
import { ModalAlert } from "../../../../shared/components/Modals/ModalAlert"

const emails = {
    ContenedorPrincipal: EmailSection,
    EspaciosVerticales: VerticalSpace,
    Titulo: Heading,
    Texto: EmailTextBlock,
    VarianteTexto: ComponentText,
    Boton: ComponentButton,
    Imagen,
    Fila: EmailRow,
    Columna: EmailColumn,
    Cabecera: EmailHeader,
    BarraProgreso: EmailProgress,
    Condicionales: Condicionales,
    Enlace: Enlace,
    Bucle: Bucle
}

const diplomas = {
    ContenedorPrincipal: Container,
    EspaciosVerticales: VerticalSpace,
    Titulo: Heading,
    Texto: EmailTextBlock,
    VarianteTexto: ComponentText,
    Imagen,
    Boton: ComponentButton,
    BarraProgreso: EmailProgress,
    Fila: EmailRow,
    Columna: EmailColumn,
    Enlace: Enlace
}

export const InformationPlantillas = () => {
    const timeZone = useTimeZone()
    const { user } = useAuthContex()
    const toast = useToast()
    const client = useClient()
    const { id } = useParams()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();
    const { isOpen: isOpenAlertTest, onOpen: onOpenAlertTest, onClose: onCloseAlertTest } = useDisclosure();
    const [includeTemario, setIncludeTemario] = useState<boolean>(false)

    const { data: plantilla, loading, Refresh } = useDataId({
        id: id,
        endpoint: EndpointTypes.PLANTILLAS,
        client: client
    })

    useEffect(() => {
        if (
            !loading &&
            plantilla?.contenido.includes('class="temario-container"')
        ) {
            setIncludeTemario(true)
        }
    }, [plantilla])

    const puckData = plantilla?.puck
    delete puckData?.root

    const initialData = (plantilla?.puck === null || plantilla?.puck === undefined) && plantilla?.categoria === "diploma" ?
        {
            "root": {
                "props": {
                    "title": plantilla?.nombre || "Edicion de diploma"
                }
            },
            "content": [
                {
                    "type": "ContenedorPrincipal",
                    "props": {
                        "backgroundColor": "transparent",
                        "border": "none",
                        "borderColor": "transparent",
                        "borderRadius": "0",
                        "padding": "8px",
                        "align": "center",
                        "id": "ContenedorPrincipal-5143d810-feb2-482a-ac8a-318b94defbce"
                    }
                }
            ],
            "zones": {
                "ContenedorPrincipal-5143d810-feb2-482a-ac8a-318b94defbce:diploma-content": []
            }
        }
        : (plantilla?.puck === null || plantilla?.puck === undefined) && plantilla?.categoria !== "diploma" ?
            {
                "root": {
                    "props": {
                        "title": plantilla?.nombre || "Edicion de plantilla"
                    }
                },
                "content": [
                    {
                        "type": "ContenedorPrincipal",
                        "props": {
                            "items": [
                                {
                                    "height": "auto"
                                }
                            ],
                            "layout": "horizontal",
                            "padding": "16px",
                            "maxWidth": "600px",
                            "align": "center",
                            "id": "ContenedorPrincipal-ae1e1438-1bac-4780-8921-6053761b75e7"
                        }
                    }
                ],
                "zones": {
                    "ContenedorPrincipal-ae1e1438-1bac-4780-8921-6053761b75e7:section-content-0": []
                }
            }
            :
            {
                "root": {
                    "props": {
                        "title": plantilla?.nombre || "Edicion de plantilla"
                    }
                },
                ...plantilla?.puck
            }

    const completedConfig = () => {
        if (
            user?.config &&
            user?.config?.cursos &&
            user?.config?.grupos &&
            user?.config?.matriculas &&
            user?.config?.alumnos &&
            user?.config?.tutores &&
            user?.config?.empresasOrganizadoras
        ) return true
        else return false
    }

    const save = (data: any) => {
        const render = renderToStaticMarkup(
            <Render
                config={{
                    components: plantilla?.categoria === "diploma" ? diplomas : emails,
                } as any}
                data={data}
            />
        )

        const html = `<!DOCTYPE html>
        <html lang="es">
        <head>
            <link href="https://cdnjs.cloudflare.com/ajax/libs/foundation-emails/2.2.1/foundation-emails.min.css" rel="stylesheet" />
            ${plantilla?.categoria === "diploma" ?
                '<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap" rel="stylesheet">'
                : ''}
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            ${plantilla?.categoria === "diploma" ? `
                <style>
                 ${includeTemario ?
                    `@page {
                        size: A4 landscape;
                        margin: 40px 0px 0px 0px;
                    }
                    
                    @page:first {
                        margin-top: 0px;
                    }    
                    `
                    :
                    `@page {
                        size: A4 landscape;
                        margin: 0px;
                    }`
                }   
                    
                    body {
                        overflow-x: hidden;
                        width: 1123px;
                    }

                    /* Aplicar Poppins a todos los elementos de texto */
                    body, p, h1, h2, h3, h4, h5, h6, span, div {
                        font-family: 'Poppins', sans-serif !important;
                    }

                    ${includeTemario ? `
                        .temario-container {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 20px;
                            page-break-inside: avoid;
                        }

                        .page {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-item: center;
                            width: calc(50% - 210px);
                            page-break-inside: avoid;
                            break-inside: avoid;
                            margin-left: 150px;
                        }

                        .page-break {
                            page-break-after: always;
                            break-after: page;
                        }

                        .title-tema {
                            color: #133E87;
                            font-weigth: 700;
                        }

                        .space {
                            margin-top: 50px;
                        }`
                    : ""
                }
                </style>
            ` : `
                <style>
                    /* Estilos base para texto */
                    body {
                        -webkit-text-size-adjust: 100%;
                        -ms-text-size-adjust: 100%;
                    }
                    
                    /* Media queries para diferentes tamaños de pantalla */
                    @media screen and (max-width: 600px) {
                        .h1 { font-size: 28px !important; }
                        .h2 { font-size: 22px !important; }
                        .h3 { font-size: 18px !important; }
                        .p, .text { font-size: 16px !important; }
                        .small { font-size: 14px !important; }
                    }
                    
                    @media screen and (max-width: 480px) {
                        .h1 { font-size: 24px !important; }
                        .h2 { font-size: 20px !important; }
                        .h3 { font-size: 16px !important; }
                        .p, .text { font-size: 14px !important; }
                        .small { font-size: 12px !important; }
                        
                        /* Ajuste de padding para móviles */
                        .container {
                            padding: 10px !important;
                        }
                        
                        /* Ajuste de imágenes */
                        img {
                            max-width: 100% !important;
                            height: auto !important;
                        }
                    }
                </style>
            `}
        </head>

        <body>
            ${render.replaceAll("&quot;", '"').replaceAll("&gt;", ">").replaceAll("&lt;", "<").replaceAll("&amp;", "&").replaceAll("{{ fechaExpedicion }}", `${formatTimezone({ date: new Date().toISOString(), timeZone, customFormat: "dd/MM/yyyy" })}`)}
            
            ${includeTemario ? `
                <div class="temario-container">
                    @set('itemsPerPage', 6)

                @each((value, key, index) in temarioCurso.temario)
                    @if (index > 0 && index % itemsPerPage === 0)
                        </div>
                        <div class="page-break"></div>
                        <div class="temario-container">
                    @endif

                    <div
                        class="page"
                        style={
                            @if(index % 2 === 0) 
                                'grid-column: 1;'
                            @else
                                'grid-column: 2;'
                            @endif
                        }   
                    >
                        <h2 class="title-tema"> {{ value.tema }}</h2>

                        <ul>
                            @each(subtema in value.subtemas)
                                <li> {{ subtema }} </li>
                            @end
                        </ul>
                    </div>
                    @end
                </div >`
                : ""
            }
        </body>
        </html>
        `

        updatePlantilla({
            id: plantilla?.id,
            data: {
                nombre: data.root.props.title || plantilla?.nombre,
                contenido: html,
                puck: data
            },
            client: client
        })
            .then(() => {
                toastNotify(toast, StatusEnumTypes.SUCCESS, "La plantilla se ha actualizado correctamente")
                Refresh()
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    };

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Plantillas
                </Text>
            }
            buttonBack="/plantillas/todo"
        >
            {loading
                ?
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    height="calc(100vh - 100px)"
                >
                    <Spinner
                        boxSize="150px"
                        borderWidth="3px"
                    />
                </Flex>
                :
                <Flex
                    direction="column"
                >
                    <Puck
                        config={{
                            components: plantilla?.categoria === "diploma" ? diplomas : emails,
                        } as any}
                        data={initialData}
                        onPublish={save}
                        overrides={{
                            iframe: ({ children }) => {
                                return (
                                    <Flex
                                        w="100%"
                                        h={plantilla?.categoria === "diploma" ? "774px" : "100%"}
                                    >
                                        {children}
                                    </Flex>
                                )
                            },
                            headerActions: ({ children }) => {
                                return (
                                    <Flex
                                        alignItems="center"
                                        gap="15px"
                                    >
                                        <Flex
                                            justifyContent="center"
                                            gap="10px"
                                            alignItems="center"
                                        >
                                            <Text
                                                color="font"
                                                fontSize="13px"
                                                fontWeight="600"
                                            >
                                                Añadir temario
                                            </Text>

                                            <Switch
                                                size="md"
                                                colorScheme="black"
                                                sx={{
                                                    'span.chakra-switch__track': {
                                                        bg: 'gray.200',
                                                    },
                                                    '&[data-checked] span.chakra-switch__track': {
                                                        bg: 'main',
                                                    }
                                                }}
                                                isChecked={includeTemario}
                                                onChange={(e) => {
                                                    setIncludeTemario(e.target.checked)
                                                    onOpenAlert()
                                                }}
                                            />
                                        </Flex>

                                        <Tooltip
                                            label="Debe completar toda la configuración para poder probar las plantillas o diplomas"
                                            placement="left"
                                            hasArrow
                                            isDisabled={plantilla && completedConfig()}
                                        >
                                            <Button
                                                px="18px"
                                                py="7px"
                                                h="fit-content"
                                                w="fit-content"
                                                color="pureblack"
                                                border="1px solid"
                                                borderColor="pureblack"
                                                rounded="8px"
                                                fontSize="14px"
                                                fontWeight="700"
                                                bg="purewhite"
                                                _hover={{ bg: "#e4e4e6" }}
                                                leftIcon={
                                                    <Icon
                                                        as={HiOutlineDocumentMagnifyingGlass}
                                                        boxSize="20px"
                                                    />
                                                }
                                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                    e.stopPropagation()

                                                    onOpenAlertTest()
                                                }}
                                            >
                                                {plantilla?.categoria === "diploma" ? "Probar Diploma" : "Probar Email"}
                                            </Button>
                                        </Tooltip>

                                        {children}
                                    </Flex>
                                )
                            }
                        }}
                        viewports={[
                            {
                                "width": 680,
                                "height": "auto",
                                "icon": "Tablet",
                                "label": "Medium"
                            },
                            {
                                "width": plantilla?.categoria === "diploma" ? 1123 : 900,
                                "height": "auto",
                                "icon": "Monitor",
                                "label": "Large"
                            }
                        ]}
                    />

                    <TestPlantillasModal
                        isOpen={isOpen}
                        onClose={onClose}
                        plantilla={plantilla}
                    />

                    <ModalAlert
                        isOpen={isOpenAlert}
                        onClose={onCloseAlert}
                        title={includeTemario ? "Has añadido el temario, debes guardar la plantilla para que se apliquen los cambios" : "Has quitado el temario, debes guardar la plantilla para que se apliquen los cambios"}
                        onlyAccept={true}
                    />

                    <ModalAlert
                        isOpen={isOpenAlertTest}
                        onClose={onCloseAlertTest}
                        title={"Si has realizado cambios, debes guardar la plantilla antes de probarla. ¿Quieres continuar?"}
                        onConfirm={onOpen}
                    />
                </Flex>
            }
        </Topbar >
    )
}