import { Flex } from '@chakra-ui/react'
import { Helmet } from 'react-helmet'
import { Route, Routes } from 'react-router-dom'
import { TabsFundae } from './views/TabsFundae'

export default function Fundae() {
    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Helmet>
                <title>Fundae </title>
            </Helmet>

            <Flex direction="column">
                <Routes>
                    <Route
                        path="/*"
                        element={
                            <TabsFundae />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    )
}
