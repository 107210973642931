import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export interface QuerySessionProps {
    entity: EntityTypes | null;
}

export const useQuerySession = ({ entity }: QuerySessionProps) => {
    const navigate = useNavigate()
    const [query, setQuery] = useState<Record<string, string | number | string[]>>({})

    useEffect(() => {
        if (!entity) return

        const defaultQuery = localStorage.getItem(entity)

        if (defaultQuery) setQuery(JSON.parse(defaultQuery))
    }, [])

    useEffect(() => {
        if (!entity) return

        localStorage.setItem(entity, JSON.stringify(query))
    }, [query])

    const handleSetQuery = (
        newQuery: Record<string, string | number | string[]>,
        redirectTo?: string
    ) => {
        if (!entity) return

        localStorage.setItem(entity, JSON.stringify(newQuery))
        setQuery(newQuery)

        if (redirectTo) navigate(redirectTo)
    }

    return {
        query,
        setQuery,
        handleSetQuery
    }
};

export enum EntityTypes {
    /**
     * Entidades de generales
     */
    CURSOS = "cursosQuery",
    GRUPOS = "gruposQuery",
    ALUMNOS = "alumnosQuery",
    ADMINISTRADORES = "administradoresQuery",
    TUTORES = "tutoresQuery",
    SUPERVISORES = "supervisoresQuery",
    INSPECTORES = "inspectoresQuery",
    AUDITS = "auditsQuery",
    EXAMENES = "examenesQuery",
    TENANT = "tenantQuery",
    CORRECCIONES = "correccionesQuery",
    CORRECCIONES_PENDIENTES = "correccionesPendientesQuery",
    MATRICULAS = "matriculasQuery",
    FEEDBACK_USERS = "feedbackUsersQuery",
    FEEDBACK_CONFIG = "feedbackConfigQuery",
    LIQUIDACIONES = "liquidacionesQuery",
    ASIGNACIONES_LIQUIDADAS = "asignacionesLiquidadasQuery",
    ASIGNACIONES_PENDIENTES = "asignacionesPendientesQuery",
    EMPRESAS = "empresasQuery",
    FUNDAE_FINALIZACIONES = "fundaeFinalizacionesQuery",
    TAREAS = "tareasQuery",
    EMAILS_LOGS = "emailsLogsQuery",
    EMAILS_PENDIENTES = "emailsPendientesQuery",
    DATOS = "datosQuery",
    FORO = "foroQuery",
    MENSAJERIA = "mensajeriaQuery",
    HOJAS_DE_RUTA = "hojasDeRutaQuery",
    INSCRIPCIONES = "inscripcionesQuery",
    EVALUACIONES = "evaluacionesQuery",

    /**
    * Entidades de seccion
    */
    SEGUIMIENTO_ATENCION = "seguimientoAtencionQuery",
    LIQUIDACIONES_ID = "liquidacionesIdQuery",
    CURSOS_TUTOR = "cursosTutorQuery",
    MATRICULAS_GRUPO = "matriculasGrupoQuery",
    MATRICULAS_ALUMNO = "matriculasAlumnoQuery",
    GRUPOS_CURSO = "gruposCursoQuery",
    GRUPOS_EMPRESA = "gruposEmpresaQuery",
    GRUPOS_INSPECTOR = "gruposInspectorQuery",
    GRUPOS_SUPERVISOR = "gruposSupervisorQuery",
    GRUPOS_TUTOR = "gruposTutorQuery",
    INFORMES_MATRICULA = "informesMatriculaQuery",
    INFORMES_GRUPO = "informesGrupoQuery",
    ANUNCIOS_CURSO = "anunciosCursoQuery",
    SESIONES_MATRICULA = "sesionesMatriculaQuery",
    ALUMNOS_EMPRESA = "alumnosEmpresaQuery",
    FEEDBACK_CURSO = "feedbackCursoQuery",
    MATRICULAS_DATOS = "matriculasDatosQuery",

    /**
    * Entidades especificas
    */
    GRUPOS_TAREAS = "gruposTareasQuery",
    GRUPOS_EMAILS_LOGS = "gruposEmailsLogsQuery",
    GRUPOS_EMAILS_PENDIENTES = "gruposEmailsPendientesQuery",
    MATRICULAS_TAREAS = "matriculasTareasQuery",
    MATRICULAS_EMAILS_LOGS = "matriculasEmailsLogsQuery",
    MATRICULAS_EMAILS_PENDIENTES = "matriculasEmailsPendientesQuery",
    USUARIOS_TAREAS = "usuariosTareasQuery",
    USUARIOS_EMAILS_LOGS = "usuariosEmailsLogsQuery",
    USUARIOS_EMAILS_PENDIENTES = "usuariosEmailsPendientesQuery",

    /**
    * Entidades rol inspector
    */
    INSPECTOR_ALUMNOS = "inspectorAlumnosQuery",
    INSPECTOR_GRUPOS = "inspectorGruposQuery",
    INSPECTOR_INFORMES = "inspectorInformesQuery",
}