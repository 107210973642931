import { Flex } from "@chakra-ui/react"
import { ExamenInt, PreguntaInt } from "../../../../../../interfaces/ExamenInt"
import { useEffect, useState } from "react";
import { EditPregunta } from "../../../../components/utils/EditPregunta";
import { SkeletonContenidoExamenes } from "../../../../components/utils/ExmanesContenidoSkeleton";
import { QueryObserverResult } from "@tanstack/react-query";
import { AccordeonContenidoExamen } from "../../../../components/utils/AccordeonContenidoExamen";
import { useSearchParams } from "react-router-dom";

export enum TipoSelect {
    PREGUNTA = "PREGUNTA",
    RESPUESTA = "RESPUESTA",
    NUEVA_RESPUESTA = "NUEVA_RESPUESTA"
}

interface Props {
    examen: ExamenInt;
    Refresh: () => Promise<QueryObserverResult>;
    loading: boolean
}

export const TabContenidoExamen = ({
    examen,
    Refresh,
    loading
}: Props) => {
    const [selected, setSelected] = useState<PreguntaInt>()
    const [search] = useSearchParams();

    useEffect(() => {
        if (search.has("pregunta") && examen?.preguntas?.length) {
            const preguntaId = search.get("pregunta");
            const pregunta = examen?.preguntas?.find((p: PreguntaInt) => p?.id?.toString() === preguntaId);

            if (pregunta) {
                setSelected(pregunta);
            }
        }
    }, [examen?.preguntas]);

    return (
        loading ?
            <SkeletonContenidoExamenes />
            :

            <Flex
                gap="15px"
                h={"calc(100vh - 170px)"}
            >
                <Flex
                    w="25%"
                    position={"relative"}
                    minW="300px"
                >
                    <AccordeonContenidoExamen
                        selected={selected}
                        setSelected={setSelected}
                        examen={examen}
                        refreshData={Refresh}
                    />
                </Flex>

                <Flex
                    w="75%"
                >
                    {selected &&
                        <EditPregunta
                            pregunta={selected}
                            Refresh={Refresh}
                            setSelected={setSelected}
                        />
                    }

                </Flex>
            </Flex>
    )
}