import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Flex,
    Icon,
    Button,
    useToast,
    useDisclosure,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom';
import { useClient } from '../../../shared/context/client.context';
import { useRef, useState } from 'react';
import { BiDownload } from 'react-icons/bi';
import { toastNotify } from '../../../shared/utils/functions/toastNotify';
import { StatusEnumTypes } from '../../../shared/utils/Types/StatusEnumTypes';
import { getInformes } from '../../../shared/middlewares/informes.middleware';
import { InformesEnumTypes } from '../../../shared/utils/Types/InformesEnumTypes';
import { IngresoAlumno } from './Avisos/IngresoAlumno';
import { InformesModal } from './Avisos/InformesModal';
import { InformesGeneralModal } from './Avisos/InformesGeneralModal';
import { useDataRelationship } from '../../../shared/hooks/useDataRelationship';
import { EndpointTypes, RelationshipTypes } from '../../../shared/utils/Types/EndpointTypes';
import { FiltrosNames, FiltrosTypes } from '../../../shared/utils/Types/FiltrosEnum';
import { handleErrors } from '../../../shared/utils/functions/handleErrors';
import { MatriculasInt } from '../../../interfaces/MatriculasInt';
import { loginViaId } from '../../../shared/middlewares/token.middleware';
import { UserInt } from '../../../interfaces/UserInt';
import { getKey } from '../../../shared/middlewares/config.middleware';
import { fmtSnds } from '../../../shared/utils/functions/fmtSnds';
import { useAuthContex } from '../../../shared/context/auth.context';
import { Filters } from '../../../shared/components/Filters/Filters';
import { Table } from '../../../shared/components/Table/Table';
import { InformationColumns } from './Columns/InformationColumns';
import { EntityTypes, useQuerySession } from '../../../shared/hooks/useQuerySession';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onOpenResumen: () => void;
    matriculaIdRef: React.MutableRefObject<string | null>;
}

export const InformationModal = ({
    isOpen,
    onClose,
    onOpenResumen,
    matriculaIdRef
}: Props) => {
    const { id } = useParams();
    const client = useClient();
    const toast = useToast();

    const { onOpen: onOpenIngreso, isOpen: isOpenIngreso, onClose: onCloseIngreso } = useDisclosure();
    const { onOpen: onOpenInformes, isOpen: isOpenInformes, onClose: onCloseInformes } = useDisclosure();
    const { onOpen: onOpenGeneral, isOpen: isOpenGeneral, onClose: onCloseGeneral } = useDisclosure();
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.INSPECTOR_INFORMES });
    const [matricula, setMatricula] = useState<MatriculasInt>()
    const { data: informes, loading } = useDataRelationship({
        id: id,
        client: client,
        endpoint: EndpointTypes.GRUPOS,
        relationship: RelationshipTypes.MATRICULAS,
        specificQuery: {
            deBaja: "false",
        },
        query: query,
    })
    const userIdRef = useRef<UserInt>();

    const dowloadInformeAlumno = (
        matricula: MatriculasInt | undefined,
        grupoId: string,
        setLoading: (loading: boolean) => void
    ) => {
        if (!matricula?.id) return
        setLoading(true)

        let informes = Object.values(InformesEnumTypes)

        if (!checkRequisitos(matricula)) informes = informes.filter((informe: string) => informe !== InformesEnumTypes.DIPLOMA)

        getInformes({
            data: {
                grupoId: grupoId,
                matriculas: [
                    {
                        id: matricula?.id,
                        informes: informes,
                        debug: true
                    }
                ]
            },
            client: client
        })
            .then(() => toastNotify(toast, StatusEnumTypes.SUCCESS, "Los informes se están generando, recibirá una notificación con el enlace de descarga"))
            .catch((error) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => {
                onCloseInformes();
                setLoading(false)
            })
        setLoading(false)
    }

    const dowloadInformesGenerales = (
        setLoading: (loading: boolean) => void
    ) => {
        setLoading(true)
        const matriculas: { id: string, informes: string[], debug: boolean }[] = []

        informes?.data?.map((matricula: MatriculasInt) => {
            if (!matricula?.id) return

            let informesTypes = Object.values(InformesEnumTypes)

            if (!checkRequisitos(matricula)) informesTypes = informesTypes?.filter((informe: string) => informe !== InformesEnumTypes.DIPLOMA)

            matriculas.push({
                id: matricula?.id,
                informes: informesTypes,
                debug: true
            })
        })

        getInformes({
            data: {
                grupoId: id as string,
                matriculas
            },
            client: client
        })
            .then(() => toastNotify(toast, StatusEnumTypes.SUCCESS, "Los informes se están generando, recibirá una notificación con el enlace de descarga"))
            .catch((error) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => {
                onCloseGeneral();
                setLoading(false)
            })
    }

    const handleLoginViaId = async () => {
        if (!userIdRef.current?.id) return;

        const { data } = await getKey("userURL", client)
        setIsLoggingIn(true);

        loginViaId({
            userId: userIdRef.current?.id,
            client: client
        })
            .then((response) => {
                const token = response?.data?.token?.token;

                return window.location.href = `${data.data}/login?loginById=true&token=${token}`;
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setIsLoggingIn(false))
    };

    const checkRequisitos = (matricula: MatriculasInt): boolean => {
        if (
            matricula?.puntuacionMedia && matricula?.puntuacionMedia >= 75 &&
            matricula?.progresoEntregables && matricula?.progresoEntregables >= 75 &&
            matricula?.progresoEntregables >= 75 &&
            parseInt(fmtSnds(matricula?.tiempoAcceso)) >= (Number(matricula?.grupo?.curso?.duracion) * 75) / 100
        ) return true;

        return false;
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => onClose()}
                closeOnOverlayClick={false}
                size="xs"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    rounded="20px"
                >
                    <ModalCloseButton onClick={onClose} />

                    <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        mt="50px"
                        px="40px"
                    >
                        <ModalHeader
                            fontSize="24px"
                            fontWeight="600"
                            letterSpacing="-0.32px"
                            p="0"
                        >
                            Matrículas
                        </ModalHeader>

                        <Button
                            h="fit-content"
                            p="10px 15px"
                            bg="black"
                            color="white"
                            fontSize="14px"
                            fontWeight="600px"
                            rightIcon={<Icon as={BiDownload} boxSize="20px" />}
                            _hover={{ bg: "light_grey", color: "black" }}
                            onClick={onOpenGeneral}
                        >
                            Descargar todos los informes
                        </Button>
                    </Flex>

                    <Box
                        m="10px"
                        mb="20px"
                        overflowY="scroll"
                        overflowX="hidden"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <ModalBody
                            display="flex"
                            flexDirection="column"
                            gap="20px"
                        >
                            <Filters
                                query={query}
                                setQuery={setQuery}
                                allowToggle={true}
                                showBody={false}
                                filterHeaders={[
                                    {
                                        name: FiltrosNames.NOMBRE,
                                        type: FiltrosTypes.INPUT,
                                    },
                                ]}
                            />
                            <Table
                                query={query}
                                setQuery={setQuery}
                                data={informes?.data || []}
                                columns={InformationColumns({
                                    userIdRef,
                                    onOpenIngreso,
                                    setMatricula,
                                    onOpenInformes,
                                    matriculaIdRef,
                                    onOpenResumen,
                                    onClose
                                })}
                                isLoading={loading}
                                total={informes?.meta?.total || 0}
                                lessWidthSize={0}
                            />
                        </ModalBody>
                    </Box>
                </ModalContent>
            </Modal>

            <IngresoAlumno
                isOpen={isOpenIngreso}
                onClose={onCloseIngreso}
                onCloseIngreso={onClose}
                onLogin={handleLoginViaId}
                isLoading={isLoggingIn}
            />

            <InformesModal
                isOpen={isOpenInformes}
                onClose={onCloseInformes}
                matricula={matricula}
                grupoId={id as string}
                onDowload={dowloadInformeAlumno}
            />

            <InformesGeneralModal
                isOpen={isOpenGeneral}
                onClose={onCloseGeneral}
                onDowload={dowloadInformesGenerales}
            />
        </>
    )
}