import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  LabelList,
} from "recharts";
import { Flex } from "@chakra-ui/react";
import { TooltipProps } from "recharts";
import { ValueType, NameType } from "recharts/types/component/DefaultTooltipContent";
import { useModuloFundae } from "../../../../shared/hooks/ModuloFundae";

interface ReactChartProps {
  data: any[];
  colors?: Record<string, string>
  isImporte?: boolean;
}

export default function ReactChart({ data, colors, isImporte }: ReactChartProps) {
  const moduloFUNDAE = useModuloFundae();

  const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active) {
      return (
        <Flex
          p="24px"
          direction="column"
          width="fit-content"
          rounded="14px"
          borderWidth="1px"
          borderColor="#DFE2E6"
          bgColor={"purewhite"}
          transition="all 0.3s ease-in-out"
        >
          <Flex
            textColor="#616675"
            fontWeight={"600"}
            marginBottom="8px"
          >
            {label === 'ENE' ? "Enero"
              : label === 'FEB' ? "Febrero"
                : label === 'MAR' ? "Marzo"
                  : label === 'ABR' ? "Abril"
                    : label === 'MAY' ? "Mayo"
                      : label === 'JUN' ? "Junio"
                        : label === 'JUL' ? "Julio"
                          : label === 'AGO' ? "Agosto"
                            : label === 'SEP' ? "Septiembre"
                              : label === 'OCT' ? "Octubre"
                                : label === 'NOV' ? "Noviembre"
                                  : label === 'DIC' ? "Diciembre"
                                    : label
            }
          </Flex>
          <Flex
            align="center"
            justify="start"
            gap="8px"
          >
            <Flex
              boxSize={"9px"}
              rounded={"2px"}
              bgColor={"main"}
            />
            <Flex
              textColor="#616675"
            >
              No FUNDAE:
              {payload ? payload[0]?.payload?.matriculasNoFundae : 0}
            </Flex>
          </Flex>

          {moduloFUNDAE &&
            <Flex
              align="center"
              justify="start"
              gap="8px"
            >
              <Flex
                boxSize={"9px"}
                rounded={"2px"}
                bgColor={"variant"}
              />
              <Flex
                textColor="#616675"
              >
                FUNDAE : {payload ? payload[0]?.payload?.matriculasFundae : 0}
              </Flex>
            </Flex>
          }
        </Flex>
      )
    }
    return null
  }

  const CustomLegend = () => (

    <Flex
      mt={"10px"}
      align={"center"}
      justify={"center"}
      gap={"28px"}
    >
      {moduloFUNDAE &&
        <Flex
          align={"center"}
          justify={"center"}
          gap={"8px"}
          fontSize={"14px"}
          fontWeight={"400"}
          textColor={"#616675"}
        >
          <Flex
            boxSize={"9px"}
            rounded={"2px"}
            bgColor={"variant"}
          />
          FUNDAE
        </Flex>
      }

      <Flex
        align={"center"}
        justify={"center"}
        gap={"8px"}
        fontSize={"14px"}
        fontWeight={"400"}
        textColor={"#616675"}
      >
        <Flex
          boxSize={"9px"}
          rounded={"2px"}
          bgColor={"main"}
        />
        No FUNDAE
      </Flex>
    </Flex>

  )

  return (
    <Flex
      w="100%"
      h={isImporte ? 480 : 300}
    >
      <ResponsiveContainer
        width={"100%"}
        height={"100%"}
      >
        <BarChart
          maxBarSize={115}
          data={data}
        >
          <Tooltip
            cursor={false}
            content={<CustomTooltip />}
            isAnimationActive
            animationDuration={150}
            animationEasing="ease"
          />

          <XAxis
            dataKey={"fecha"}
            tickLine={false}
            axisLine={false}
          />

          {moduloFUNDAE &&
            <Bar
              dataKey="matriculasFundae"
              stackId="a"
              fill={colors?.variant}
            >
            </Bar>
          }

          <Bar
            dataKey="gap"
            stackId="a"
            fill="transparent"
          >
          </Bar>

          <Bar
            dataKey="matriculasNoFundae"
            stackId="a"
            fill={colors?.main}
            radius={[5, 5, 0, 0]}
          >
            <LabelList
              dataKey={"matriculasTotales"}
              position={"top"}
              fontSize={"13px"}
            />
          </Bar>

          <Legend
            content={<CustomLegend />}
            verticalAlign="bottom"
          />

          <YAxis
            hide
          />

        </BarChart>
      </ResponsiveContainer>
    </Flex>
  );
}