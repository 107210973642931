import { CategoriaProfesionalOptions } from "../../../../shared/utils/Types/TipoCategoriaProfesional";
import { NivelEstudiosOptions } from "../../../../shared/utils/Types/TipoNivelEstudios";

interface Field {
    label: string;
    key: string;
    alternateMatches?: string[];
    fieldType: {
        type: "input" | "checkbox" | "select";
        options?: { value: string; label: string }[];
        booleanMatches?: { [key: string]: boolean };
    };
    validations: {
        rule: string;
        errorMessage: string;
        level: string;
    }[];
}

export const getFields = ({
    isTutorUnico,
    isEmpresaUnica,
    tutores,
    empresas = [],
    moduloFUNDAE,
    externa
}: {
    isTutorUnico: boolean | undefined | null;
    isEmpresaUnica: boolean | undefined;
    tutores: { value: string, label: string }[],
    empresas: { value: string, label: string }[],
    moduloFUNDAE: boolean | undefined;
    externa: boolean | undefined;
}) => {
    const fields: Field[] = [
        {
            label: "Nombre",
            key: "nombre",
            alternateMatches: ["Nombre", "nombre", "NOMBRE"],
            fieldType: {
                type: "input",
            },
            validations: [
                {
                    rule: "required",
                    errorMessage: "El nombre es obligatorio",
                    level: "error",
                },
            ],
        },
        {
            label: "Apellidos",
            key: "apellidos",
            alternateMatches: ["Apellidos", "apellidos", "APELLIDOS"],
            fieldType: {
                type: "input",
            },
            validations: [
                {
                    rule: "required",
                    errorMessage: "El nombre es obligatorio",
                    level: "error",
                },
            ],
        },
        {
            label: "Email",
            key: "email",
            alternateMatches: ["Email", "email", "EMAIL"],
            fieldType: {
                type: "input",
            },
            validations: [
                {
                    rule: "required",
                    errorMessage: "El nombre es obligatorio",
                    level: "error",
                },
            ],
        },
        {
            label: "Teléfono",
            key: "telefono",
            alternateMatches: ["Teléfono", "teléfono", "TELÉFONO"],
            fieldType: {
                type: "input",
            },
            validations: [],
        },
        {
            label: "Nif o Nie",
            key: "dni",
            alternateMatches: ["Nif", "Nie", "NIF", "NIE", "Nif o Nie", "NIF o NIE", "Nif/Nie", "NIF/NIE", "DNI", "dni"],
            fieldType: {
                type: "input",
            },
            validations: [],
        },
    ]

    if (!isTutorUnico && isTutorUnico !== null) {
        fields.push({
            label: "Tutor",
            key: "tutorId",
            fieldType: {
                type: "select",
                options: tutores
            },
            validations: [
                {
                    rule: "required",
                    errorMessage: "El tutor es obligatorio",
                    level: "error",
                },
            ],
        })
    }

    if (!isEmpresaUnica) {
        fields.push(
            {
                label: "Empresa",
                key: "empresaId",
                fieldType: {
                    type: "select",
                    options: empresas
                },
                validations: [
                    {
                        rule: "required",
                        errorMessage: "La empresa es obligatoria",
                        level: "error",
                    },
                ],
            })
    }

    if (moduloFUNDAE && !externa) {
        fields.push(
            {
                label: "Nivel de estudios",
                key: "nivelEstudios",
                alternateMatches: [
                    "Nivel Estudios",
                    "Nivel de estudios",
                    "NivelEstudios",
                    "Nivel_Estudios",
                    "Estudios",
                    "Nivel"
                ],
                fieldType: {
                    type: "select",
                    options: NivelEstudiosOptions
                },
                validations: [
                    {
                        rule: "required",
                        errorMessage: "El nivel de estudios es obligatorio",
                        level: "error",
                    },
                ],
            },
            {
                label: "Cat. profesional",
                key: "categoriaProfesional",
                alternateMatches: [
                    "Cat. profesional",
                    "Categoria Profesional",
                    "CategoriaProfesional",
                    "Categoria_Profesional",
                    "Profesional",
                    "Categoria"
                ],
                fieldType: {
                    type: "select",
                    options: CategoriaProfesionalOptions
                },
                validations: [
                    {
                        rule: "required",
                        errorMessage: "La categoría profesional es obligatoria",
                        level: "error",
                    },
                ],
            },
            {
                label: "Coste/hora",
                key: "costeHora",
                alternateMatches: [
                    "Coste/hora", 
                    "coste/hora", 
                    "COSTE/HORA",
                    "Coste por hora",
                    "coste por hora",
                    "COSTE POR HORA",
                    "Coste salariar trabajador/hora (1800horas al año)",
                    "Coste salariar trabajador/hora",
                    "Coste salariar",
                    "Coste salarial trabajador/hora (1800horas al año)",
                    "Coste salarial trabajador/hora",
                    "Coste salarial",
                ],
                fieldType: {
                    type: "input",
                },
                validations: [
                    {
                        rule: "required",
                        errorMessage: "El coste por hora es obligatorio",
                        level: "error",
                    },
                ],
            },
            {
                label: "Fijo discontinuo",
                key: "fijoDiscontinuo",
                alternateMatches: ["si", "sí", "Si", "Sí", "SI", "SÍ", "no", "No", "NO"],
                fieldType: {
                    type: "checkbox",
                    booleanMatches: {
                        "si": true,
                        "sí": true,
                        "Si": true,
                        "Sí": true,
                        "SI": true,
                        "SÍ": true,
                        "no": false,
                        "No": false,
                        "NO": false
                    }
                },
                validations: []
            }
        )
    }

    return fields;
}