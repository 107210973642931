import React from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, FormControl, FormLabel, Input, Text, Select } from '@chakra-ui/react'

interface Props {
    isOpen: boolean;
    onClose: () => void;
    numMatriculas: number;
    setNumMatriculas: (num: number) => void;
    handleSubmit: (supervisorId: string) => void;
    supervisores: { data: any[] };
}
export default function NewSolicitudPrematriculas({ isOpen, onClose, numMatriculas, setNumMatriculas, handleSubmit, supervisores }: Props) {
    const [selectedSupervisor, setSelectedSupervisor] = React.useState<string>("");

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="xl"
            isCentered
        >
            <ModalOverlay />
            <ModalContent
            >
                <ModalHeader>
                    Solicitar Matrículas
                </ModalHeader>

                <ModalBody
                    gap="10px"
                >
                    <Text
                        mt="-10px"
                    >
                        Se enviará un correo a la empresa el cual le redireccionará al campus para rellenar los datos de matriculación.
                    </Text>

                    <FormControl
                        mt="20px"
                    >
                        <FormLabel>¿A quién quieres enviar el correo?</FormLabel>
                        <Select
                            placeholder="Seleccione un supervisor"
                            value={selectedSupervisor}
                            onChange={(e) => setSelectedSupervisor(e.target.value)}
                        >
                            {supervisores?.data?.map((supervisor: any) => (
                                <option key={supervisor.id} value={supervisor?.id}>
                                    {supervisor?.nombreCompleto}
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl
                        mt="20px"
                    >
                        <FormLabel>Número de matrículas</FormLabel>
                        <Input
                            type="number"
                            value={numMatriculas}
                            onChange={(e) => setNumMatriculas(parseInt(e.target.value))}
                            min={1}
                        />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button
                        bg="transparent"
                        color="main"
                        border="2px solid"
                        borderColor="main"
                        mr={3}
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        bg="main"
                        color="white"
                        onClick={() => handleSubmit(selectedSupervisor)}
                        isDisabled={!selectedSupervisor}
                    >
                        Enviar solicitud
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
