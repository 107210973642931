import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from '../services/tenantInstance.service';
import { DateTime } from "luxon";

export const addMatriculaToGrupo = async ({ 
    data, 
    client
} : {
    data: {
        userId?: string;
        grupoId?: string;
        tutorId?: string;
        matriculas?: any[]
    },
    client: ClientInt
}) => {
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/matriculas`,
        data,
        {}
    )
};

export const updateMatricula = async ({ 
    id, 
    data,
    client 
}: { 
    id: string, 
    data: any,
    client: ClientInt
}) => {
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/matriculas/${id}`,
        data,
        {}
    )
};

export const bajaMatricula = async ({ id, client } : { id: string | undefined , client: ClientInt }) => {
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/matriculas/${id}`,
        {
            fechaBaja: DateTime.now()
        },
        {}
    )
};

export const deleteMatricula = async ({ matriculaId, client } : { matriculaId: string | undefined , client: ClientInt }) => {
    if(!client) return;
    const tenant = await tenantInstance();

    return _axios.delete(`${tenant}/${client}/matriculas/${matriculaId}`).then((response: any) => response)
};