export const formatNumberByTimezone = (value: number, timezone: string): string => {
    let locale: string;
    try {
        const regionCode = Intl.DateTimeFormat(undefined, {
            timeZone: timezone,
            localeMatcher: 'best fit'
        })
            .resolvedOptions()
            .locale;

        new Intl.NumberFormat(regionCode).format(1);
        locale = regionCode;
    } catch {
        locale = 'es-ES';
    }

    return new Intl.NumberFormat(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'decimal'
    }).format(value);
};