function filterNonEmptyItems(data: any[]): any[] {
    return data.filter(item => {
        for (const key in item) {
            if (item[key] === null || item[key] === undefined) return false
            
            else if (key && key !== "__index" && item[key]?.trim() !== "") {
                return true
            }
        }
        return false
    });
}

export default filterNonEmptyItems