import React, { useEffect, useState } from 'react'
import { Topbar } from '../../../../../shared/components/Topbar/Topbar'
import { Button, Flex, Text, useToast } from '@chakra-ui/react'
import { Filters } from '../../../../../shared/components/Filters/Filters'
import { FiltrosTypes } from '../../../../../shared/utils/Types/FiltrosEnum'
import { FiltrosNames } from '../../../../../shared/utils/Types/FiltrosEnum'
import { EntityTypes, useQuerySession } from '../../../../../shared/hooks/useQuerySession'
import { isRoleAllowed } from '../../../../../shared/utils/functions/validateRol'
import { UserRolEnum } from '../../../../../shared/utils/Types/UserRolEnum'
import { useAuthContex } from '../../../../../shared/context/auth.context'
import { Table } from '../../../../../shared/components/Table/Table'
import { useData } from '../../../../../shared/hooks/useData'
import { EndpointTypes } from '../../../../../shared/utils/Types/EndpointTypes'
import { useClient } from '../../../../../shared/context/client.context'
import { EmptyMessageType } from '../../../../../shared/utils/Types/EmptyMessageType'
import { FinalizacionColumns } from './Columns/FinalizacionColumns'
import { GruposInt } from '../../../../../interfaces/GruposInt'
import { downloadXML } from '../../../../../shared/middlewares/adjuntos.middleware'
import { TipoXML } from '../../../../../shared/utils/Types/TipoXLM'
import { handleErrors } from '../../../../../shared/utils/functions/handleErrors'
import { toastNotify } from '../../../../../shared/utils/functions/toastNotify'
import { StatusEnumTypes } from '../../../../../shared/utils/Types/StatusEnumTypes'

export default function FinalizacionTable() {
    const client = useClient();
    const toast = useToast();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.FUNDAE_FINALIZACIONES });
    const [hasFilter, setHasFilters] = useState<boolean>();
    const [availableSelect, setAvailableSelect] = useState<boolean>(false)
    const [selectedFinalizaciones, setSelectedFinalizaciones] = useState<any>(null);
    const [loadingXML, setLoadingXML] = useState<boolean>();
    const { data: grupos, loading, Refresh, error, status, errorsList } = useData({
        client: client,
        endpoint: EndpointTypes.GRUPOS,
        specificQuery: {
            fundae: "true",
            gestionEmpresa: "false"
        },
        query: query
    })

    useEffect(() => {
        if (query?.empresaOrganizadora && query?.fechaFinInf) {
            setAvailableSelect(true)
        } else {
            setSelectedFinalizaciones([])
            setAvailableSelect(false)
        }

    }, [query])

    const handleDownloadXML = async () => {
        if (!selectedFinalizaciones) return;
        setLoadingXML(true)

        const gruposList = selectedFinalizaciones?.map((g: GruposInt) => g?.id)

        downloadXML({
            data: {
                tipo: TipoXML.FinalizacionGrupo,
                grupos: gruposList,
            },
            client
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response?.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `finalizaciones.xml`);
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setLoadingXML(false))
    }

    return (
        <Flex
            direction="column"
            gap="20px"
        >
            <Filters
                setHasFilters={setHasFilters}
                query={query}
                setQuery={setQuery}
                allowToggle={true}
                filterElements={[
                    {
                        name: FiltrosNames.EMPRESA_ORGANIZADORA,
                        type: FiltrosTypes.ASYNC_SELECT,
                    },
                    {
                        name: FiltrosNames.FECHA_FIN_INF,
                        type: FiltrosTypes.DATE,
                    },
                ]}
            />

            <Table
                query={query}
                setQuery={setQuery}
                data={grupos?.data || []}
                columns={FinalizacionColumns({ availableSelect })}
                isLoading={loading}
                selection={selectedFinalizaciones}
                onSelectionChange={(e: any) => setSelectedFinalizaciones(e?.value)}
                total={grupos?.meta?.total || 0}
                emptyMessage={hasFilter ? EmptyMessageType.gruposFiltros : EmptyMessageType.grupos}
            />

            <Button
                bg="main"
                color="purewhite"
                py="15px"
                w="250px"
                h="fit-content"
                mx="auto"
                onClick={handleDownloadXML}
                isDisabled={
                    (!selectedFinalizaciones || selectedFinalizaciones?.length === 0) || !availableSelect
                }
                isLoading={loadingXML}
            >
                Descargar XML
            </Button>
        </Flex>
    )
}
