import { ExamenInt, CreateExamenInt, PreguntaInt, CreatePreguntaInt, RespuestaInt, QuizData } from '../../interfaces/ExamenInt';
import { ClientInt } from '../services/http.service';
import _axios from "../services/http.service";
import tenantInstance from '../services/tenantInstance.service';


export const updateExamen = async ({ id, data, client }: { id: string, data: ExamenInt, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/examenes/${id}`,
        data,
        {}
    )
};

export const addExamen = async ({ examen, client }: { examen: CreateExamenInt, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/examenes`,
        examen,
        {}
    )
};

export const updatePregunta = async ({ id, data, client }: { id: string, data: PreguntaInt, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/preguntas/${id}`,
        data,
        {}
    )
};

export const addPregunta = async ({ pregunta, client }: { pregunta: CreatePreguntaInt, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/preguntas`,
        pregunta,
        {}
    )
};

export const deletePregunta = async ({ preguntaId, client }: { preguntaId: string, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.delete(
        `${tenant}/${client}/preguntas/${preguntaId}`
    )
};

export const updateRespuesta = async ({ id, data, client }: { id: string, data: Partial<RespuestaInt>, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/respuestas/${id}`,
        data,
        {}
    )
};

export const addRespuesta = async ({ respuesta, client }: { respuesta: Partial<RespuestaInt>, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/respuestas`,
        respuesta,
        {}
    )
};

export const deleteRespuesta = async ({ client, respuestaId }: { client: ClientInt, respuestaId: string }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.delete(
        `${tenant}/${client}/respuestas/${respuestaId}`
    )
};

export const deleteExamen = async ({ examenId, client }: { examenId: string | undefined, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    return _axios.delete(`${tenant}/${client}/examenes/${examenId}`)
};

export const generateExamIA = async ({ file, numberQuestion, client }: { file: File, numberQuestion: number, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    const formData = new FormData()
    formData.append('file', file);
    formData.append('numeroPreguntas', numberQuestion.toString());

    return _axios.post(`${tenant}/${client}/ai/creaExamen`, formData)
};

export const createExamIA = async ({ exam, id, client }: { exam: QuizData, id: string, client: ClientInt }) => {
    if (!client) return;
    const tenant = await tenantInstance();

    const data = {
        contenido: exam
    };

    return _axios.post(`${tenant}/${client}/ai/procesaExamen/${id}`, data);
};