import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    Flex,
    Box,
    useToast,
    ModalCloseButton,
} from '@chakra-ui/react'
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from 'yup';
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { FormTextarea } from '@imaginagroup/bit-components.ui.form-textarea';
import { useState } from 'react';
import { toastNotify } from '../../../../shared/utils/functions/toastNotify';
import { StatusEnumTypes } from '../../../../shared/utils/Types/StatusEnumTypes';
import { handleErrors } from '../../../../shared/utils/functions/handleErrors';
import { useClient } from '../../../../shared/context/client.context';
import { CreateExamenInt } from '../../../../interfaces/ExamenInt';
import { FormAsyncSelect } from "@imaginagroup/bit-components.ui.form-async-select";
import { QueryTypes } from '../../../../shared/utils/Types/QueryTypes';
import { EndpointTypes } from '../../../../shared/utils/Types/EndpointTypes';
import { loadData } from '../../../../shared/utils/functions/loadData';
import { FormTimeInput } from '../../../../shared/components/FormElements/FormTimeInput';
import { addExamen } from '../../../../shared/middlewares/examenes.middleware';
import { formSelectStyles } from '../../../../ui/formSelectStyles';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    Refresh: () => void;
}

export const NewExamenModal = ({ isOpen, onClose, Refresh }: Props) => {
    const client = useClient();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const toast = useToast();

    const initialValues: CreateExamenInt = {
        nombre: null,
        publicado: false,
        descripcion: null,
        duracion: 0,
        numIntentos: null,
    };

    const validationSchema = Yup.object().shape({
        nombre: Yup.string()
            .required('¡El título del curso es obligatorio!')
            .typeError('¡El título del curso es obligatorio.'),
        publicado: Yup.boolean()
            .notRequired(),
        descripcion: Yup.string()
            .notRequired(),
        duracion: Yup.string()
            .required('¡La duración del curso es obligatoria!')
            .typeError('¡La duración del curso es obligatoria.'),
        numIntentos: Yup.number()
            .required('¡El número de intentos es obligatorio!')
            .typeError('¡El número de intentos es obligatorio.'),
    });

    const handleSubmit = (values: any) => {
        setIsLoading(true)

        const examen = {
            nombre: values.nombre.trim(),
            publicado: values.publicado,
            descripcion: values.descripcion,
            duracion: values.duracion,
            numIntentos: values.numIntentos,
        }

        addExamen({
            examen,
            client: client
        })
            .then(() => {
                Refresh();
                handleClose();

                toastNotify(toast, StatusEnumTypes.SUCCESS, `Se ha creado el examen ${examen.nombre}`)
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setIsLoading(false))
    }

    const loadCursos = async (value: string) => await loadData({
        value: value,
        endpoint: EndpointTypes.CURSOS,
        client: client,
        query: QueryTypes.NOMBRE,
    })

    const handleClose = () => {
        onClose()
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => null}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="3xl"
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                maxH="95vh"
                rounded="15px"
            >
                <Box
                    color="black"
                >
                    <ModalHeader
                        textAlign="start"
                        fontSize="24px"
                        fontWeight="600"
                        px="30px"
                        pt="30px"
                        pb="0px"
                    >
                        Nuevo examen
                    </ModalHeader>

                    <ModalCloseButton onClick={() => handleClose()} />
                </Box>

                <Box
                    overflowY="auto"
                    __css={{
                        '&::-webkit-scrollbar': {
                            w: '7px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '20px',
                            bg: `light_grey`,
                        },
                    }}
                >
                    <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                        {(formik) => {
                            const { handleSubmit, values } = formik;

                            return (
                                <FormikForm
                                    onSubmit={handleSubmit}
                                >
                                    <ModalBody
                                        p="30px"
                                    >

                                        <Flex
                                            direction="column"
                                            boxSize="100%"
                                            gap="20px"
                                        >

                                            <Flex
                                                gap="15px"
                                            >
                                                <FormInput
                                                    name="nombre"
                                                    label="Nombre"
                                                    placeholder='Nombre del examen'
                                                    isRequired={!values?.nombre ? true : false}
                                                />

                                                <FormSelect
                                                    isRequired={values?.publicado === null || values?.publicado === undefined}
                                                    name="publicado"
                                                    label="Estado"
                                                    placeholder="Selecciona una opción"
                                                    options={[
                                                        { label: 'Publicado', value: true },
                                                        { label: 'Oculto', value: false },
                                                    ]}
                                                    ui={{
                                                        formSelectStyles: formSelectStyles,
                                                    }}
                                                />
                                            </Flex>

                                            <Flex
                                                gap="15px"
                                            >
                                                <FormInput
                                                    name="numIntentos"
                                                    type='number'
                                                    step={0}
                                                    min={0}
                                                    label="Número de intentos"
                                                    placeholder='Número de intentos'
                                                    isRequired={!values?.numIntentos ? true : false}
                                                />

                                                <FormTimeInput
                                                    name="duracion"
                                                    label="Duración"
                                                    isRequired={!values?.duracion ? true : false}
                                                />
                                            </Flex>

                                            <FormTextarea
                                                name="descripcion"
                                                label="Descripción"
                                                ui={{
                                                    minHeigh: '150px'
                                                }}
                                            />
                                        </Flex>
                                    </ModalBody>

                                    <ModalFooter
                                        justifyContent="center"
                                        py="30px"
                                    >
                                        <Button
                                            isLoading={isLoading}
                                            type="submit"
                                            bg="secondary"
                                            h="fit-content"
                                            p="10px 25px"
                                            rounded="5"
                                            color="purewhite"
                                            fontSize="18px"
                                            fontWeight="500"
                                            _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                        >
                                            Crear examen
                                        </Button>
                                    </ModalFooter>
                                </FormikForm>
                            );
                        }}
                    </Formik>
                </Box>
            </ModalContent>
        </Modal>
    )
}