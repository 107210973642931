import { Box, Button, Checkbox, Flex, Icon, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { PreguntaInt, RespuestaInt } from "../../../../interfaces/ExamenInt";
import { InformationBox } from "../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { Dispatch, SetStateAction, useState } from "react";
import { useClient } from "../../../../shared/context/client.context";
import { deletePregunta, updatePregunta, updateRespuesta } from "../../../../shared/middlewares/examenes.middleware";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { Form as FormikForm, Formik } from 'formik';
import { FormTextarea } from "@imaginagroup/bit-components.ui.form-textarea";
import { ModalDelete } from "../../../../shared/components/Modals/ModalDelete";
import { BiTrash } from "react-icons/bi";
import { QueryObserverResult } from "@tanstack/react-query";
import { EditRespuestasModal } from "../Modals/EditRespuestasModal";

interface Props {
    pregunta: PreguntaInt;
    Refresh: () => Promise<QueryObserverResult>;
    setSelected: Dispatch<SetStateAction<PreguntaInt | undefined>>;
}

export const EditPregunta = ({ pregunta, Refresh, setSelected }: Props) => {
    const client = useClient();
    const toast = useToast();
    const [loading, setLoading] = useState<boolean>(false)
    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
    const { isOpen: isOpenEditRespuestas, onOpen: onOpenEditRespuestas, onClose: onCloseEditRespuestas } = useDisclosure();

    const initialValues = {
        contenido: pregunta?.contenido,
        explicacionRespuesta: pregunta?.explicacionRespuesta
    }

    const handleSubmit = async (values: any) => {
        setLoading(true);

        return updatePregunta({
            id: pregunta?.id as string,
            data: {
                contenido: values?.contenido,
                explicacionRespuesta: values?.explicacionRespuesta
            },
            client: client
        })
            .then(async () => {
                await Refresh()
                setSelected((prev: PreguntaInt | undefined) => {
                    if (!prev) return undefined;

                    return {
                        ...prev,
                        contenido: values?.contenido,
                        explicacionRespuesta: values?.explicacionRespuesta
                    } as PreguntaInt
                });

                toastNotify(toast, StatusEnumTypes.SUCCESS, "Campo actualizado correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setLoading(false))
    }

    const onDelete = () => {

        if (!pregunta?.id) {
            toastNotify(toast, StatusEnumTypes.ERROR, "ID de pregunta no válido");
            return;
        }

        deletePregunta({ preguntaId: pregunta?.id, client: client })
            .then(() => {
                Refresh()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Pregunta eliminada correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => onCloseDelete())
    }

    const isExistsChanges = (
        values: {
            contenido: string | undefined | null;
            explicacionRespuesta: string | undefined | null;
        }
    ) => {
        const initContent = pregunta?.contenido?.replaceAll("<p><br></p>", "")?.replaceAll("\n", "")
        const newContent = values?.contenido?.replaceAll("<p><br></p>", "")?.replaceAll("\n", "")

        const initExplanation = pregunta?.explicacionRespuesta?.replaceAll("<p><br></p>", "")?.replaceAll("\n", "");
        const newExplanation = values?.explicacionRespuesta?.replaceAll("<p><br></p>", "")?.replaceAll("\n", "");

        if (initContent !== newContent) return true
        if (initExplanation !== newExplanation) return true;

        return false
    }

    const handleRespuestaCorrecta = async (id: string) => {
        const oldSelected = pregunta
        const oldRespuestaCorrecta = pregunta?.respuestas?.find((respuesta: RespuestaInt) => respuesta?.correcta)
        if (oldRespuestaCorrecta?.id === id) return;

        setSelected((prev: PreguntaInt | undefined) => {
            if (!prev) return undefined;

            return {
                ...prev,
                respuestas: prev?.respuestas?.map((respuesta: RespuestaInt) => {
                    if (respuesta?.id === id) return { ...respuesta, correcta: true }
                    if (respuesta?.id === oldRespuestaCorrecta?.id) return { ...respuesta, correcta: false }
                    return respuesta
                })
            } as PreguntaInt
        });

        await Promise.all([
            oldRespuestaCorrecta &&
            updateRespuesta({
                id: oldRespuestaCorrecta?.id as string,
                data: { correcta: false },
                client: client
            }),
            updateRespuesta({
                id: id,
                data: { correcta: true },
                client: client
            })
        ])
            .then(async () => await Refresh())
            .catch((error: any) => {
                setSelected(oldSelected)

                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    return (
        <Flex
            w="100%"
            direction="column"
            px="5px"
            pt="10px"
            css={{
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
                'scrollbarWidth': 'none',
                '-ms-overflow-style': 'none',
                scrollBehavior: 'smooth',
            }}
            overflow="auto"
            h="calc(100vh - 175px)"
        >
            <Formik
                onSubmit={handleSubmit}
                enableReinitialize
                initialValues={initialValues}
            >
                {(formik) => {
                    const { handleSubmit, values } = formik;

                    return (
                        <FormikForm
                            onSubmit={handleSubmit}
                        >
                            <Flex>
                                <InformationBox
                                    noHeader
                                >
                                    <Box>
                                        <Flex
                                            direction="column"
                                            w="full"
                                        >
                                            <Flex
                                                alignItems="center"
                                                gap="10px"
                                                justifyContent="space-between"
                                                my="20px"
                                                px="20px"
                                            >
                                                <Text
                                                    w="full"
                                                    color="main"
                                                    fontSize="18px"
                                                    fontWeight="500"
                                                >
                                                    Pregunta
                                                </Text>

                                                <Flex
                                                    alignItems="center"
                                                    gap="10px"
                                                >
                                                    <Text
                                                        display={!isExistsChanges(values) ? "none" : "block"}
                                                        opacity={!isExistsChanges(values) ? 0 : 1}
                                                        color="fail"
                                                        fontSize="14px"
                                                        fontWeight="600"
                                                    >
                                                        Borrador
                                                    </Text>

                                                    <Button
                                                        display={isExistsChanges(values) ? "none" : "flex"}
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        h="fit-content"
                                                        w="fit-content"
                                                        py="7.4px"
                                                        px="10px"
                                                        rounded={"6px"}
                                                        bg="secondary"
                                                        fontSize="14px"
                                                        color="purewhite"
                                                        fontWeight="400"
                                                        onClick={(e: React.MouseEvent) => {
                                                            e.stopPropagation()

                                                            if (isOpenDelete) onCloseDelete();
                                                            onOpenDelete();
                                                        }}
                                                    >
                                                        <Icon
                                                            boxSize="22px"
                                                            as={BiTrash}
                                                        />
                                                    </Button>

                                                    <Button
                                                        display={!isExistsChanges(values) ? "none" : "block"}
                                                        h="fit-content"
                                                        w="fit-content"
                                                        py="10px"
                                                        px="10px"
                                                        rounded={"6px"}
                                                        bg="secondary"
                                                        fontSize="14px"
                                                        color="purewhite"
                                                        fontWeight="400"
                                                        type="submit"
                                                        isDisabled={!pregunta?.id || !isExistsChanges(values)}
                                                        isLoading={loading}
                                                    >
                                                        Guardar
                                                    </Button>
                                                </Flex>
                                            </Flex>

                                            <Flex
                                                px="20px"
                                                direction="column"
                                                alignItems="start"
                                                justifyContent="space-between"
                                                gap="20px"
                                                pb="20px"
                                            >
                                                <FormTextarea
                                                    noHead
                                                    name="contenido"
                                                    ui={{
                                                        minHeigh: "50px",
                                                        styleLabel: {
                                                            color: "font",
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                        },
                                                        styles: {
                                                            border: "1px solid",
                                                            borderColor: "#E2E8F0",
                                                            borderRadius: "5px",
                                                            color: "font",
                                                            padding: "5px",
                                                            maxHeight: "100px",
                                                        }
                                                    }}
                                                />
                                            </Flex>

                                        </Flex>

                                        <Flex
                                            direction="column"
                                            gap="10px"
                                            mb="20px"
                                            px="20px"
                                        >
                                            <Text
                                                w="full"
                                                color="main"
                                                fontSize="18px"
                                                fontWeight="500"
                                                mb="15"
                                            >
                                                Explicación de la respuesta
                                            </Text>

                                            <Flex
                                                direction="column"
                                                alignItems="start"
                                                justifyContent="space-between"
                                                gap="20px"
                                            >

                                                <FormTextarea
                                                    noHead
                                                    name="explicacionRespuesta"
                                                    ui={{
                                                        minHeigh: "50px",
                                                        styleLabel: {
                                                            color: "font",
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                        },
                                                        styles: {
                                                            border: "1px solid",
                                                            borderColor: "#E2E8F0",
                                                            borderRadius: "5px",
                                                            color: "font",
                                                            padding: "5px",
                                                            maxHeight: "100px",
                                                        }
                                                    }}
                                                />
                                            </Flex>
                                        </Flex>
                                    </Box>
                                </InformationBox>
                            </Flex>
                        </FormikForm>
                    );
                }}
            </Formik>


            <Flex>
                <InformationBox
                    noHeader
                >
                    <Flex
                        direction="column"
                        w="full"
                    >
                        <Flex
                            gap="10px"
                            my="20px"
                            px="20px"
                            direction="column"
                        >
                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Text
                                    color="main"
                                    fontSize="18px"
                                    fontWeight="500"
                                >
                                    Respuestas
                                </Text>

                                <Text
                                    color="secondary"
                                    fontSize="14px"
                                    fontWeight="400"
                                    cursor="pointer"
                                    textDecoration="underline"
                                    _hover={{
                                        color: "main"
                                    }}
                                    onClick={(e: React.MouseEvent) => {
                                        e.stopPropagation()
                                        onOpenEditRespuestas()
                                    }}
                                >
                                    Editar respuestas
                                </Text>
                            </Flex>

                            <Flex
                                direction="column"
                                gap="15px"
                                w="100%"
                            >
                                {pregunta?.respuestas?.map((respuesta: any, index: number) => (
                                    <Flex
                                        key={index}
                                        py="15px"
                                        px="30px"
                                        w="100%"
                                        border="1px solid"
                                        alignItems="center"
                                        borderColor={respuesta?.correcta ? "main" : "#E6E6EA"}
                                        bg="transparant"
                                        rounded="8px"
                                        _hover={{ border: "1px solid", borderColor: "main" }}
                                        gap="20px"
                                        cursor="pointer"
                                        onClick={() => handleRespuestaCorrecta(respuesta?.id)}
                                    >
                                        <Checkbox
                                            value={respuesta?.id}
                                            onChange={() => handleRespuestaCorrecta(respuesta?.id)}
                                            isChecked={respuesta?.correcta}
                                        />

                                        <Text
                                            fontSize="14px"
                                            fontWeight="400"
                                            color={respuesta?.correcta ? "main" : "font"}
                                            lineHeight="20px"
                                        >
                                            {respuesta.contenido}
                                        </Text>
                                    </Flex>
                                ))}
                            </Flex>
                        </Flex>
                    </Flex>
                </InformationBox>
            </Flex>

            <ModalDelete
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                onDelete={onDelete}
                label="pregunta"
            />

            <EditRespuestasModal
                isOpen={isOpenEditRespuestas}
                onClose={onCloseEditRespuestas}
                respuestas={pregunta?.respuestas}
                preguntaId={pregunta?.id}
                refreshData={Refresh}
                setSelected={setSelected}
            />
        </Flex>
    )
}