// Chakra UI
import { Box, Button, Flex, FormLabel, Icon, Image, Input, InputGroup, InputRightElement, Text, useToast } from "@chakra-ui/react";
// React
import { useNavigate, useParams } from 'react-router-dom'
// Img
import loginImg from '../../assets/img/Login_img.png'
import logoProcademy from '../../assets/img/LogoProcademyBlack.svg'

import { StatusEnum, notify } from "../../shared/utils/functions/notify";
import * as Yup from "yup"
import { useFormik } from "formik";

import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { useAuthContex } from "../../shared/context/auth.context";
import { checkHashExists, setPassword } from "../../shared/middlewares/token.middleware";

export const Recovery = () => {
    const { hash } = useParams();
    const { login } = useAuthContex();
    const navigate = useNavigate();
    const toast = useToast();
    const [showPass, setShowPass] = useState(false);
    const [show, setShow] = useState(false);
    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    useEffect(() => {
        if (!hash) return

        checkHashExists(hash).catch(handleHashCaducado)
    }, [hash])

    const handleSubmit = (values: any) => {

        if (!hash) return

        const newPassword = {
            hashString: hash,
            password: values.password,
            passwordRepeat: values.passwordRepeat
        }

        setPassword(newPassword)
            .then((response: AxiosResponse) => login(response?.data?.token?.token, response?.data?.data, navigate))
            .catch(handleHashCaducado)
    };

    const handleHashCaducado = () => {
        notify(toast, StatusEnum.error, "El email ha caducado vuelva a pedir un cambio de contraseña")
        return navigate("/login")
    }

    const formik = useFormik({
        initialValues: {
            password: "",
            passwordRepeat: "",
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .matches(passwordRules, { message: "La contraseña debe tener mas de 8 carácteres, y contener mayúsculas, minúsculas y números" })
                .required('Este campo es obligatorio.')
                .typeError('Este campo es obligatorio.'),
            passwordRepeat: Yup.string()
                .oneOf([Yup.ref("password")], 'Las contraseñas deben coincidir.')
                .required('Este campo es obligatorio.')
                .typeError('Este campo es obligatorio.'),
        }),
        onSubmit: (values: any) => handleSubmit(values)
    });

    return (
        <Flex
            direction="column"
            w="100%"
            bg="purewhite"
        >
            <Flex
                h="90vh"
                w="100%"
            >
                <Flex flex="1">
                    <Flex flex="1" flexDirection="column" pt="5%" pb="2%" backgroundColor="#FFFFFF">
                        <Image
                            src={logoProcademy}
                            alt='img/logo'
                            objectFit="contain"
                            w="200px"
                            p="0 3%"
                            ml="40px"
                        />

                        <Box w="50%" m="auto" ml="45%">
                            <Text fontWeight="700" lineHeight="normal" fontSize="26px" color="main">Establecer nueva contraseña</Text>

                            <Box mt="10">
                                <form onSubmit={formik.handleSubmit}>
                                    <Box>
                                        <FormLabel fontSize="13px" fontWeight="bold">Contraseña</FormLabel>

                                        <InputGroup>
                                            <Input
                                                type={showPass ? 'text' : 'password'}
                                                id="password"
                                                name="password"
                                                variant='filled'
                                                placeholder='Introduzca la contraseña'
                                                size='md'
                                                focusBorderColor='secondary'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.password}
                                            />


                                            <InputRightElement>
                                                <Icon
                                                    as={showPass ? BiHide : BiShow}
                                                    boxSize="24px"
                                                    cursor="pointer"
                                                    onClick={() => setShowPass(!showPass)}
                                                    title={showPass ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                                                />
                                            </InputRightElement>
                                        </InputGroup>

                                        {
                                            formik.touched.password && formik.errors.password
                                                ? (<Box color='red' fontSize="12px" mt="5px">{formik.errors.password as string}</Box>)
                                                : null
                                        }
                                    </Box>

                                    <Box mt="8">
                                        <FormLabel fontSize="13px" fontWeight="bold">Confirme la contraseña</FormLabel>
                                        <InputGroup>
                                            <Input
                                                type={show ? 'text' : 'password'}
                                                name="passwordRepeat"
                                                id="passwordRepeat"
                                                variant='filled'
                                                placeholder='Confirme la contraseña'
                                                size='md'
                                                focusBorderColor='secondary'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.passwordRepeat}
                                            />

                                            <InputRightElement>
                                                <Icon
                                                    as={show ? BiHide : BiShow}
                                                    boxSize="24px"
                                                    cursor="pointer"
                                                    onClick={() => setShow(!show)}
                                                    title={show ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                                                />
                                            </InputRightElement>
                                        </InputGroup>

                                        {
                                            formik.touched.passwordRepeat && formik.errors.passwordRepeat
                                                ? (<Box color='red' fontSize="12px" mt="5px">{formik.errors.passwordRepeat as string}</Box>)
                                                : null
                                        }
                                    </Box>

                                    <Button type='submit' background='main' color="#FFFFFF" mt="8" w="100%" fontWeight="500">Confirmar</Button>
                                </form>
                            </Box>
                        </Box>
                    </Flex>
                </Flex>

                <Flex
                    justifyContent="flex-end"
                    flex="1"
                >
                    <Image
                        src={loginImg}
                        alt="login/img"
                        objectFit="cover"
                    />
                </Flex>
            </Flex>

            <Flex
                h="10vh"
                alignItems="center"
                justifyContent="center"
            >
                <Text
                    color="secondary_font"
                    fontSize="16px"
                    fontWeight="400"
                    letterSpacing="-0.443px"
                >
                    Copyright © 2023 Procademy by Imagina Formación
                </Text>
            </Flex>
        </Flex>
    );
}