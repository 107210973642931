import { Flex, useDisclosure } from "@chakra-ui/react";
import { useClient } from "../../../../../shared/context/client.context";
import { useRef } from "react";
import { ModalPrview } from "../../../../../shared/components/Modals/ModalPreview";
import { useData } from "../../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { MensajesColumns } from "./Columns/MensajesColumns";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";
import { CustomGroupTable } from "../../../../../shared/components/Table/CustomGroupTable";
import { ConversacionesColumns } from "./Columns/ConversacionesColumns";

export const MensajesTable = () => {
    const client = useClient();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.MENSAJERIA })

    const { data: mensajes, loading } = useData({
        client: client,
        endpoint: EndpointTypes.MENSAJERIA,
        specificQuery: {
            estado: "no_leido",
            sortBy: "createdAt",
            order: "desc",
            limit: 700,
        },
    })
    const MensajeRef = useRef<any>(null)

    const onEventView = (data: any) => {
        MensajeRef.current = data;
        onOpen();
    }

    const mensajesAgrupados = mensajes?.data?.reduce((acc: any[], mensaje: any) => {
        const conversacionId = mensaje.conversacionId;
        const conversacionExistente = acc.find((conv) => conv.id === conversacionId);

        if (conversacionExistente) {
            conversacionExistente.mensajes.push({
                id: mensaje.id,
                texto: mensaje.texto,
                estado: mensaje.estado,
                createdAt: mensaje.createdAt,
                emisorId: mensaje.emisorId,
                receptorId: mensaje.receptorId,
                emisor: mensaje.emisor?.nombreCompleto,
                receptor: mensaje.receptor?.nombreCompleto
            });
        } else {
            acc.push({
                ...mensaje.conversacion,
                comunicacion: {
                    emisor: mensaje.emisor?.nombreCompleto,
                    receptor: mensaje.receptor?.nombreCompleto,
                },
                mensajes: [{
                    id: mensaje.id,
                    texto: mensaje.texto,
                    estado: mensaje.estado,
                    createdAt: mensaje.createdAt,
                    emisorId: mensaje.emisorId,
                    receptorId: mensaje.receptorId,
                    emisor: mensaje.emisor?.nombreCompleto,
                    receptor: mensaje.receptor?.nombreCompleto
                }]
            });
        }

        return acc;
    }, []);

    return (
        <Flex>
            <CustomGroupTable
                data={mensajesAgrupados || []}
                loading={loading}
                columns={ConversacionesColumns()}
                groupByKey="mensajes"
                columnsGroup={MensajesColumns({ onEventView })}
                hideFooter={true}
                setQuery={setQuery}
                query={query}
            />

            <ModalPrview
                isOpen={isOpen}
                onClose={onClose}
                data={MensajeRef.current}
            />
        </Flex>
    )
}
