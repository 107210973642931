import { ModulosInt, updateModulosInt } from "../../interfaces/CursosInt";
import _axios, { ClientInt } from "../services/http.service";
import tenantInstance from '../services/tenantInstance.service';

export const updateModulo = async ({ 
    id, 
    data,
    client,
}: { 
    id: string, 
    data: updateModulosInt,
    client: ClientInt 
}) => {
    if(!client) return;

    const tenant = await tenantInstance();

    return _axios.put(
        `${tenant}/${client}/modulos/${id}`,
        data,
        {}
    )
};

export const addModulo = async ({ modulo, client } : { modulo: ModulosInt, client: ClientInt }) => {
    if(!client) return;

    const tenant = await tenantInstance();

    return _axios.post(
        `${tenant}/${client}/modulos`,
        modulo,
        {}
    )
};

export const removeModulo = async ({ id, client } : { id: string, client: ClientInt }) => {
    if(!client) return;

    const tenant = await tenantInstance();

    return _axios.delete(
        `${tenant}/${client}/modulos/${id}`,
    )
};