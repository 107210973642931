import { Flex } from "@chakra-ui/react"
import { useEffect } from "react";
import { useClient } from "../../../../../shared/context/client.context";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { useData } from "../../../../../shared/hooks/useData";
import { FiltrosNames, FiltrosTypes } from "../../../../../shared/utils/Types/FiltrosEnum";
import { Table } from "../../../../../shared/components/Table/Table";
import { Filters } from "../../../../../shared/components/Filters/Filters";
import { AtencionColumns } from "./Columns/AtencionColumns";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";

export const AtencionTable = () => {
    const client = useClient();
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.SEGUIMIENTO_ATENCION });
    const { data, loading } = useData({
        client: client,
        endpoint: EndpointTypes.MATRICULAS,
        specificQuery: {
            deBaja: "false",
        },
        query: query,
    });

    useEffect(() => {
        setQuery({
            estado: 0,
            fundae: "true",
            infProgresoLecciones: 50,
            semanas: 1
        })
    }, [])

    return (
        <Flex direction="column" gap="20px" w="100%">
            <Filters
                query={query}
                setQuery={setQuery}
                allowToggle={true}
                filterHeaders={[
                    {
                        name: FiltrosNames.NOMBRE_EMAIL,
                        type: FiltrosTypes.INPUT,
                    },
                ]}
                filterElements={[
                    {
                        name: FiltrosNames.SEMANAS,
                        type: FiltrosTypes.SELECT,
                    },
                ]}
            />

            <Table
                data={data?.data || []}
                columns={AtencionColumns()}
                isLoading={loading}
                total={data?.meta?.total || 0}
                query={query}
                setQuery={setQuery}
            />
        </Flex>
    )
}