import { FaRegCalendarMinus } from "react-icons/fa"
import { TabsRouterInt } from "../../../interfaces/ComponentsInt"
import FinalizacionTable from "./Table/Finalizaciones/FinalizacionTable"
import { Topbar } from "../../../shared/components/Topbar/Topbar"
import { Flex, Text } from "@chakra-ui/react"
import { TabsRouter } from "../../../shared/components/TabsRouter/TabsRouter"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export const TabsFundae = () => {
    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
        const subPaths = [
            "/fundae/finalizaciones",
        ];

        const isSubPath = subPaths.includes(location.pathname);

        if (!isSubPath) {
            navigate("finalizaciones");
        }
    }, [location.pathname]);

    const tabs: TabsRouterInt[] = [
        {
            isAllowed: true,
            path: "finalizaciones",
            label: "Finalizaciones",
            icon: FaRegCalendarMinus,
            content: <FinalizacionTable />
        }
    ]

    return (
        <Topbar
            title={
                <Text
                    color="font"
                    fontSize="20px"
                    fontWeight="400"
                >
                    Fundae
                </Text>
            }
        >
            <Flex
                direction="column"
                w="100%"
            >
                <Flex
                    direction="column"
                    p="30px"
                >
                    <TabsRouter
                        tabs={tabs}
                    />
                </Flex>
            </Flex>
        </Topbar>
    )
}