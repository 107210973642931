import { Section } from "../Section/Section";

export type ImagenProps = {
    alt: string;
    imageUrl: string;
    width?: string;
    height?: string;
    rounded?: string;
    shadow?: "none" | "sm" | "md" | "lg" | "xl" | "2xl";
    alignContent?: "center" | "left" | "right";
    objectFit?: "cover" | "contain" | "none";
};

export const Imagen = {
    fields: {
        alt: { type: "text", label: "Texto alternativo" },
        imageUrl: { type: "text", label: "URL de la imagen" },
        width: { type: "text", label: "Width" },
        height: { type: "text", label: "Height" },
        objectFit: {
            type: "select",
            label: "Object fit",
            options: [
                { value: "cover", label: "Cover" },
                { value: "contain", label: "Contain" },
                { value: "none", label: "None" }
            ]
        },
        rounded: {
            type: "select",
            label: "Rounded",
            options: [
                { value: "none", label: "None" },
                { value: "4px", label: "4px" },
                { value: "6px", label: "6px" },
                { value: "8px", label: "8px" },
                { value: "12px", label: "12px" },
                { value: "16px", label: "16px" },
                { value: "20px", label: "20px" },
                { value: "16px", label: "16px" },
                { value: "100%", label: "Completo" }
            ]
        },
        alignContent: {
            type: "radio",
            label: "Align content",
            options: [
                { value: "left", label: "Left" },
                { value: "center", label: "Center" },
                { value: "right", label: "Right" }
            ]
        },
    },
    defaultProps: {
        alt: "Proacademy",
        imageUrl: "https://res.cloudinary.com/production-proacademy/image/upload/v1727853945/Logotipo_Proacademy_ruaz21.png",
        width: "100%",
        height: "auto",
        rounded: "none",
        shadow: "none",
        objectFit: "cover",
        alignContent: "center",
    },
    render: ({
        alt,
        imageUrl,
        width,
        rounded,
        alignContent,
        height,
        objectFit
    }: ImagenProps) => {
        return (
            <Section>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: alignContent === "center" 
                            ? "center" 
                            : alignContent === "right" 
                            ? "flex-end" 
                            : "flex-start"
                    }}
                >
                    <img
                        alt={alt}
                        src={imageUrl}
                        style={{
                            objectFit: objectFit,
                            height: height,
                            width: width,
                            maxWidth: width,
                            borderRadius: rounded
                        }}
                    />
                </div>
            </Section>
        );
    },
};
