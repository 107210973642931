// Chakra UI
import { Box, Button, Flex, Icon, Spinner, Text, useToast } from "@chakra-ui/react";
// Icons
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { UserActions } from "./Components/UserActions";
import { loadData } from "../../utils/functions/loadData";
import { EndpointTypes } from "../../utils/Types/EndpointTypes";
import { useClient } from "../../context/client.context";
import { QueryTypes } from "../../utils/Types/QueryTypes";
import { CustomGroupAsyncSelect } from "../CustomElements/CustomGroupAsyncSelect";
import { useChannel } from "ably/react";
import { customToast } from "../../utils/functions/toastNotify";
import { StatusEnumTypes } from "../../utils/Types/StatusEnumTypes";
import { Message } from "ably";
import { LuFolderClosed, LuFolderOpen } from "react-icons/lu";
import { useEffect, useRef, useState } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import { useAuthContex } from "../../context/auth.context";
import { dowloadBatche } from "../../middlewares/batches.middleware";
import { useSelectChannel } from "../../hooks/useSelectChannel";
import { useData } from "../../hooks/useData";
import { formatTimezone } from "../../utils/functions/parseDate";
import { useTimeZone } from "../../hooks/useTimeZone";
import { UserRolEnum } from "../../utils/Types/UserRolEnum";

export enum GroupTypes {
    USUARIOS = "usuarios",
    GRUPOS = "grupos",
    EMPRESAS = "empresas"
}

interface Props {
    children: JSX.Element;
    title: JSX.Element;
    buttonBack?: string;
    hideSearch?: boolean;
    hideDownloads?: boolean;
}

export const Topbar = ({ children, title, buttonBack, hideSearch = false, hideDownloads }: Props) => {
    const timeZone = useTimeZone();
    const { user } = useAuthContex();
    const channel = useSelectChannel();
    const client = useClient();
    const navigate = useNavigate();
    const toast = useToast();
    const { data: descargas, Refresh } = useData({
        client: client,
        endpoint: EndpointTypes.BATCHES,
    });
    const [openNotification, setOpenNotification] = useState<boolean>(false);
    const modalRef = useRef<HTMLDivElement | null>(null);

    useChannel(`${channel}-${user?.id}`, (message: Message) => {
        handleNewMessage(message)
    });

    useChannel(`beta-ui`, () => {
        sessionStorage.setItem("hasUpdate", JSON.stringify({ path: location.pathname, newVersion: true }))
    });

    useEffect(() => {
        const hasUpdate = JSON.parse(sessionStorage.getItem("hasUpdate") || "{}")

        if (hasUpdate?.newVersion && hasUpdate?.path !== location.pathname) {
            sessionStorage.removeItem("hasUpdate")
            window.location.reload()
        }
    }, [location.pathname]);

    const handleNewMessage = (message: Message) => {

        customToast({
            toast,
            status: StatusEnumTypes.SUCCESS,
            title: (
                <Flex
                    alignItems={"center"}
                    gap="10px"
                >
                    <Text>
                        Se ha generado un nuevo informe
                    </Text>

                    <Button
                        onClick={async () => {
                            location.href = message?.data

                            await dowloadBatche({
                                id: message?.name,
                                client: client
                            })
                                .then(() => Refresh())
                        }}
                        bg="transparent"
                        color="purewhite"
                        border={"1px solid"}
                        borderColor={"purewhite"}
                        _hover={{ bg: "purewhite", color: "font" }}
                        p="5px 10px"
                        h="fit-content"
                        fontSize="14px"
                        fontWeight="600"
                        w={"fit-content"}
                    >
                        Descargar
                    </Button>
                </Flex>
            ),
            duration: 10000,
            position: "bottom",
        })

        Refresh()
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {

                setOpenNotification(false);
            }
        };

        document.addEventListener('click', handleClickOutside);


        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const loadOptions = async (value: string) => {
        const empresas = await loadData({
            value: value,
            endpoint: EndpointTypes.EMPRESA,
            client: client,
            query: QueryTypes.NOMBRE,
            specificQuery: QueryTypes.EMPRESA_EXTERNA,
            nombreCompleto: false,
        })

        const users = await loadData({
            value: value,
            endpoint: EndpointTypes.USUARIOS,
            client: client,
            query: QueryTypes.NOMBRE_EMAIL,
            nombreCompleto: true,
        })

        const grupos = await loadData({
            value: value,
            endpoint: EndpointTypes.GRUPOS,
            client: client,
            query: QueryTypes.NOMBRE,
            nombreCompleto: false,
        })

        return {
            empresas: {
                group: GroupTypes.EMPRESAS,
                options: empresas
            },
            users: {
                group: GroupTypes.USUARIOS,
                options: users
            },
            grupos: {
                group: GroupTypes.GRUPOS,
                options: grupos
            }
        }
    }

    const handleClick = (e: any, group: string) => {
        if (group === GroupTypes.USUARIOS) {
            navigate(`/usuarios/alumnos/${e.value}/perfil`)
        }
        if (group === GroupTypes.GRUPOS) {
            navigate(`/grupos/${e.value}/configuracion`)
        }
        if (group === GroupTypes.EMPRESAS) {
            navigate(`/empresas/${e.value}/perfil`)
        }
    }

    return (
        <Box
            w="100%"
        >
            <Flex
                bgColor="#FFF"
                alignItems="center"
                justifyContent="space-between"
                w="full"
                gap="40px"
                h="60px"
                px="30px"
                borderBottom="1px solid"
                borderColor="light_grey"
            >
                <Flex
                    alignItems="center"
                >
                    {buttonBack &&
                        <Icon
                            as={BiArrowBack}
                            boxSize="28px"
                            mr="30px"
                            cursor="pointer"
                            onClick={() => navigate(buttonBack)}
                        />
                    }

                    {title}
                </Flex>

                <Flex
                    gap="20px"
                >
                    {(!hideSearch && user?.role?.nombre === UserRolEnum.ADMIN) &&
                        <Flex
                            alignItems="start"
                            boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)"
                            borderColor="light_grey"
                            color="dark_grey"
                        >
                            <Box w="100%" rounded="7px">
                                <CustomGroupAsyncSelect
                                    onClick={handleClick}
                                    loadOptions={loadOptions}
                                    placeholder={"Buscar"}
                                />
                            </Box>
                        </Flex>
                    }

                    <Flex
                        alignItems="center"
                        gap="25px"
                    >
                        {!hideDownloads &&
                            <Flex
                                onClick={(e: React.MouseEvent) => {
                                    e.stopPropagation()
                                    setOpenNotification((prev) => !prev)
                                }}
                            >
                                <Box
                                    cursor={"pointer"}
                                    position={"relative"}
                                    ref={modalRef}
                                >
                                    <Icon
                                        as={!openNotification ? LuFolderClosed : LuFolderOpen}
                                        boxSize="24px"
                                        mt="7px"
                                        transition={"all 0.5s"}
                                    />

                                    <Flex
                                        opacity={
                                            descargas?.data &&
                                                descargas?.data?.filter((descarga: any) => descarga?.ready && descarga?.downloadedAt === null).length > 0 ? 1 : 0
                                        }
                                        alignItems="center"
                                        justifyContent="center"
                                        position="absolute"
                                        bottom="-4px"
                                        right="-10px"
                                        bg="main"
                                        color="white"
                                        fontSize="11px"
                                        fontWeight="bold"
                                        w="19px"
                                        h="19px"
                                        m="0"
                                        borderRadius="50%"
                                    >
                                        <Text
                                            color="white"
                                            fontSize="10px"
                                            fontWeight="bold"
                                        >
                                            {descargas?.data?.filter((descarga: any) => descarga?.ready && descarga?.downloadedAt === null).length}
                                        </Text>
                                    </Flex>


                                    <Flex
                                        display={openNotification ? "flex" : "none"}
                                        transition={"all 0.5s"}
                                        position={"absolute"}
                                        zIndex="999"
                                        w={"295px"}
                                        top={"80%"}
                                        right={"28px"}
                                        bg="purewhite"
                                        border="1px solid"
                                        borderColor="light_grey"
                                        boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.08)"
                                        borderRadius="7px"
                                        direction="column"
                                    >
                                        {descargas?.data && descargas?.data?.length > 0 ?
                                            descargas?.data?.map((item: any, index: number) => (
                                                <Flex
                                                    key={index}
                                                    justifyContent="space-between"
                                                    alignItems="start"
                                                    cursor={"pointer"}
                                                    _hover={{ bg: "main_variant" }}
                                                    p="10px"
                                                    borderBottom="1px solid"
                                                    borderColor="light_grey"
                                                    onClick={async () => {
                                                        if (item?.ready)
                                                            location.href = item?.path

                                                        if (item?.downloadedAt === null)
                                                            await dowloadBatche({
                                                                id: item?.id,
                                                                client: client
                                                            }).then(() => Refresh())
                                                    }}
                                                >
                                                    <Flex
                                                        alignItems="start"
                                                        gap="7px"
                                                    >
                                                        <Flex
                                                            pos="relative"
                                                        >
                                                            {item.ready ?
                                                                <Icon
                                                                    as={FaRegFilePdf}
                                                                    color={"font"}
                                                                    boxSize="18px"
                                                                />
                                                                :
                                                                <Spinner
                                                                    boxSize={"18px"}
                                                                    color={"secondary"}
                                                                />
                                                            }

                                                            <Box
                                                                opacity={(item?.ready && item?.downloadedAt === null) ? 1 : 0}
                                                                h="10px"
                                                                w="10px"
                                                                bg="secondary"
                                                                rounded="100%"
                                                                pos="absolute"
                                                                top={"-3px"}
                                                                left={"-3px"}
                                                            />
                                                        </Flex>

                                                        <Flex
                                                            direction="column"
                                                        >
                                                            <Text
                                                                color="font"
                                                                fontSize="11px"
                                                                fontWeight="700"
                                                            >
                                                                {item?.grupo?.nombre}
                                                            </Text>

                                                            <Text
                                                                color="font"
                                                                fontSize="11px"
                                                                fontWeight="500"
                                                            >
                                                                {item?.grupo?.curso?.nombre}
                                                            </Text>
                                                        </Flex>
                                                    </Flex>

                                                    <Text
                                                        color="font"
                                                        fontSize="11px"
                                                        fontWeight="600"
                                                        textTransform="capitalize"
                                                        whiteSpace="nowrap"
                                                    >
                                                        {item?.createdAt ? formatTimezone({ date: item?.createdAt, timeZone, isDateTime: true }) : "---"}
                                                    </Text>
                                                </Flex>
                                            )) :
                                            <Text
                                                color="font"
                                                fontSize="14px"
                                                fontWeight="400"
                                                p="10px"
                                                textAlign={"center"}
                                            >
                                                No hay descargas
                                            </Text>
                                        }
                                    </Flex>
                                </Box>
                            </Flex>
                        }

                        <UserActions />
                    </Flex>
                </Flex>
            </Flex>

            <Box
                overflow="auto"
                maxH="calc(100vh - 65px)"
                css={{
                    '&::-webkit-scrollbar': {
                        width: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: "light_grey",
                        borderRadius: '20px',
                    },
                }}
            >
                {children}
            </Box>
        </Box>
    );
};