import axios from "axios";
import tenantInstance from "../services/tenantInstance.service";
import _axios, { ClientInt } from "../services/http.service";

export const getToken = async (data: { userId: string, password: string }) => {
    const tenant = await tenantInstance();

    return axios.post(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/auth/login`,
        data
    );
};

export const loginViaId = async ({ userId, client }: { userId: string, client: ClientInt }) => {
    const tenant = await tenantInstance();

    return await _axios.post(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/${client}/loginViaId`,
        {
            userId: userId
        }
    ).then((response: any) => response);
};

export const setPassword = async (data: { hashString: string, password: string, passwordRepeat: string }) => {
    const tenant = await tenantInstance();

    return axios.post(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/auth/setPassword`,
        data
    );
};

export const recoveryPassword = async (email: string) => {
    const tenant = await tenantInstance();

    return axios.post(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/auth/recoverPassword`,
        {
            email: email
        }
    );
};

export const resendEmail = async ({
    client,
    user,
    matricula,
    grupo
}: {
    client: ClientInt,
    user: string,
    matricula?: string
    grupo?: string
}) => {
    const tenant = await tenantInstance();

    const data = matricula ? { user, matricula } : grupo ? { user, grupo } : { user };

    return _axios.post(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/${client}/recoveryEmail`,
        data,
        {}
    ).then((response: any) => response);
};

export const recordatorioDiplomaEmail = async ({
    client,
    matriculaId
}: {
    client: ClientInt,
    matriculaId: string
}) => {
    const tenant = await tenantInstance();

    return _axios.post(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/${client}/matriculas/${matriculaId}/sendMailRecordatorioDiploma`,
        {}
    ).then((response: any) => response);
};

export const checkHashExists = async (hashString: string) => {
    const tenant = await tenantInstance();

    return axios.post(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/auth/checkHash`,
        {
            hashString: hashString
        }
    );
};

export const loadContext = async (token: string) => {
    const tenant = await tenantInstance();

    return axios.get(
        `${import.meta.env.VITE_URL_CAMPUS_API}${tenant}/auth/loadContextViaId`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    ).then((response: any) => response);
}