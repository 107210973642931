import { QuizData } from "../../../../../../interfaces/ExamenInt";
import { useState } from "react";
import { Flex, Input, Text, Button } from "@chakra-ui/react";
import DropzoneComponent from "../../../../../../shared/components/Dropzone/Dropzone";
import { generateExamIA } from "../../../../../../shared/middlewares/examenes.middleware";
import { createExamIA } from "../../../../../../shared/middlewares/examenes.middleware";
import { useClient } from "../../../../../../shared/context/client.context";
import ExamGenerate from "../../../../components/utils/ExamGenerate";
import { useParams } from "react-router-dom";
import { StatusEnumTypes } from "../../../../../../shared/utils/Types/StatusEnumTypes";
import { useToast } from "@chakra-ui/react";
import { toastNotify } from "../../../../../../shared/utils/functions/toastNotify";
import { useNavigate } from "react-router-dom";
import { GenerateExamIAModal } from "../../../../components/Modals/GenerateExamIAModal";
import { handleErrors } from "../../../../../../shared/utils/functions/handleErrors";

interface Props {
    Refresh: () => void;
}

export const TabExamIA = ({
    Refresh
}: Props) => {
    const { id } = useParams();
    const client = useClient();
    const toast = useToast();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [loadingCreateExam, setLoadingCreateExam] = useState(false);
    const [exam, setExam] = useState<QuizData | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [numberQuestion, setNumberQuestion] = useState<number>(10);

    const handleGenerateExam = () => {
        if (!exam) return;
        setLoadingCreateExam(true);

        createExamIA({
            exam: exam,
            id: id!,
            client: client
        }).then(() => {
            Refresh()
            toastNotify(toast, StatusEnumTypes.SUCCESS, "Examen creado correctamente")
            navigate(`/examenes/examenes/${id}/contenido`)
        })
            .catch((error: any) => {
                setExam(null);
                setFile(null);

                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setLoadingCreateExam(false))
    }

    const handleSetFile = (file: File) => {
        setFile(file);
        setIsLoading(true)

        generateExamIA({
            file: file,
            numberQuestion: numberQuestion,
            client: client
        })
            .then((response) => setExam(response?.data))
            .catch((error) => {
                setFile(null);

                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <Flex
            justify="center"
            align="center"
            direction="column"
            bg="purewhite"
            borderRadius="14px"
            border="1px solid"
            borderColor="#EAEAEA"
            p="30px"
            color="font"
            minH="calc(100vh - 180px)"
        >
            {!exam && !isLoading && <Text
                color="font"
                fontSize="24px"
                mb="30px"
            >
                Generar preguntas con IA
            </Text>}

            {isLoading ?
                <GenerateExamIAModal
                    nombreFile={file?.name as string}
                />
                : exam ?
                    <>
                        <ExamGenerate exam={exam} />

                        <Button
                            mt="20px"
                            onClick={handleGenerateExam}
                            colorScheme="green"
                            isLoading={loadingCreateExam}
                        >
                            Añadir preguntas
                        </Button>
                    </>

                    :
                    <Flex 
                        direction="column"
                        gap="10px"
                    >
                        <Flex
                            justify="center"
                            align="center"
                        >
                            <Text
                                pr="5px"
                                fontSize="18px"
                            >
                                Número de preguntas:
                            </Text>

                            <Input
                                type="number"
                                value={numberQuestion}
                                w="50px"
                                onChange={(e) => setNumberQuestion(parseInt(e.target.value))}
                            />
                        </Flex>

                        <Text>
                            Arrastra tu archivo aquí para poder empezar
                        </Text>

                        <DropzoneComponent
                            file={file}
                            setFile={handleSetFile as any}
                            pdfOnly={true}
                        />
                    </Flex>
            }
        </Flex>
    )
}