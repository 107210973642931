import { Badge, Box, Tooltip, Flex, Icon, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { InformationBox } from "../../../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { InformationInput } from "@imaginagroup/bit-components.ui.elements.information-input"
import { GruposInt } from "../../../../../../interfaces/GruposInt";
import { BiEnvelopeOpen, BiLock, BiPencil, BiPlusCircle } from "react-icons/bi";
import { UserInt } from "../../../../../../interfaces/UserInt";
import { EmpresasInt } from "../../../../../../interfaces/EmpresasInt";
import { SwitchItem } from "../../../../../../shared/components/CustomElements/SwitchItem";
import { useEffect, useState } from "react";
import { AsyncCard } from "../../../../../../shared/components/CustomElements/SelectCard";
import { InformationTextarea } from "@imaginagroup/bit-components.ui.elements.information-textarea"
import { InformationAsyncCard } from "../../../../../../shared/components/Information/InformationAsyncCard";
import { InformationDateInput } from "@imaginagroup/bit-components.ui.elements.information-date-input"
import { useAuthContex } from "../../../../../../shared/context/auth.context";
import { isRoleAllowed } from "../../../../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../../../../shared/utils/Types/UserRolEnum";
import { useClient } from "../../../../../../shared/context/client.context";
import { ModalFundae } from "../../../../components/Modals/ModalFundae";
import { updateGrupo } from "../../../../../../shared/middlewares/grupos.moddleware";
import { handleErrors } from "../../../../../../shared/utils/functions/handleErrors";
import { toastNotify } from "../../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../../shared/utils/Types/StatusEnumTypes";
import { EndpointTypes } from "../../../../../../shared/utils/Types/EndpointTypes";
import { QueryTypes } from "../../../../../../shared/utils/Types/QueryTypes";
import { loadData } from "../../../../../../shared/utils/functions/loadData";
import { NewSupervisorModal } from "../../../../../Usuarios/components/Modals/NewSupervisorModal";
import { CustomButton } from "../../../../../../shared/components/CustomElements/CustomButton";
import { useUserPolicy } from "../../../../../../shared/hooks/PermissionPolicy";
import { useModuloFundae } from "../../../../../../shared/hooks/ModuloFundae";
import GruposConfigSkeleton from "../../../../components/utils/GruposConfigSkeleton";
import { useData } from "../../../../../../shared/hooks/useData";
import { InformationSelect } from "@imaginagroup/bit-components.ui.elements.information-select";
import { informationSelectStyles } from "../../../../../../ui/informationSelectStyles";
import { useTimeZone } from "../../../../../../shared/hooks/useTimeZone";
import { formatTimezone } from "../../../../../../shared/utils/functions/parseDate";
import { NewTutorModal } from "../../../../../Usuarios/components/Modals/NewTutorModal";
import { OnboardingStep } from "../../../../../../shared/components/Cards/OnboardingStep";
import NewSolicitudPrematriculas from "../../../../components/Modals/NewSolicitudPrematriculas";
import { sendMailPrematriculas } from "../../../../../../shared/middlewares/emails.middleware";

interface Props {
    grupo: GruposInt | undefined;
    updateValue: (value: any) => void;
    loading: boolean;
    Refresh: () => void;
    isEmpresaUnica: boolean | undefined;
}

export const TabConfiguracion = ({ grupo, updateValue, loading, Refresh, isEmpresaUnica }: Props) => {
    const timeZone = useTimeZone();
    const moduloFUNDAE = useModuloFundae();
    const { contenido, gestion, total } = useUserPolicy();
    const { user } = useAuthContex();
    const client = useClient();
    const toast = useToast();
    const { isOpen: isOpenFundae, onOpen: onOpenFundae, onClose: onCloseFundae } = useDisclosure();
    const { isOpen: isOpenSupervisor, onOpen: onOpenSupervisor, onClose: onCloseSupervisor } = useDisclosure();
    const { isOpen: isOpenTutor, onOpen: onOpenTutor, onClose: onCloseTutor } = useDisclosure();
    const [editNombre, setEditNombre] = useState<boolean>(false)
    const [editFechaInicio, setEditFechaInicio] = useState<boolean>(false)
    const [editFechaFin, setEditFechaFin] = useState<boolean>(false)
    const [switchEmpresa, setSwitchEmpresa] = useState<boolean>(true)
    const [switchFormacion, setSwitchFormacion] = useState<boolean>()
    const [switchDiploma, setSwitchDiploma] = useState<boolean>()
    const [editImporteMatricula, setEditImporteMatricula] = useState<boolean>(false)
    const [showStep, setShowStep] = useState(true);
    const { isOpen: isOpenNewSolicitudPrematriculas, onOpen: onOpenNewSolicitudPrematriculas, onClose: onCloseNewSolicitudPrematriculas } = useDisclosure();
    const [numMatriculas, setNumMatriculas] = useState(1);

    const { data: empresas } = useData({
        client: client,
        endpoint: EndpointTypes.EMPRESA,
        query: {
            interna: "true",
        },
        ignoreRequest: (user?.role?.nombre !== UserRolEnum.ADMIN || gestion || contenido),
    })

    useEffect(() => {
        setSwitchFormacion(grupo?.fundae)
        setSwitchDiploma(grupo?.permitirDescargaDiploma)

    }, [grupo])

    const loadTutores = async (value: string) => await loadData({
        value: value,
        endpoint: EndpointTypes.USUARIOS,
        client: client,
        query: QueryTypes.NOMBRE_EMAIL,
        specificQuery: QueryTypes.ROL_TUTOR,
        nombreCompleto: true
    })

    const loadEmpresas = async (value: string) => await loadData({
        value: value,
        endpoint: EndpointTypes.EMPRESA,
        client: client,
        query: QueryTypes.NOMBRE,
    })

    const { data: supervisores, loading: loadingSupervisores, Refresh: RefreshSupervisores } = useData({
        client: client,
        endpoint: EndpointTypes.SUPERVISORES,
        specificQuery: { empresa: grupo?.empresaOrganizadoraId || "" },
    });

    const removeTutor = (tutorId: string | undefined | null) => {
        if (!tutorId) return
        const values: any[] = []

        grupo?.tutores?.filter(
            (tutor: UserInt) => tutor?.id !== tutorId)
            .map((tutor: UserInt) => values.push(tutor?.id))

        updateValue({ tutores: values })
    }

    const removeEmpresa = (empresaId: string | undefined | null) => {
        if (!empresaId) return
        const values: any[] = []

        grupo?.empresas?.filter(
            (empresa: EmpresasInt) => empresa?.id !== empresaId)
            .map((empresa: EmpresasInt) => values.push(empresa?.id))

        updateValue({ empresas: values })
    }

    const changeFundae = () => {
        if (
            !grupo?.id ||
            switchFormacion === null ||
            switchFormacion === undefined
        ) return

        if (!switchFormacion) {
            onOpenFundae()
            return
        }

        const newValues = {
            fundae: false,
            gestionEmpresa: null,
            empresaOrganizadoraId: null
        }

        updateGrupo({
            id: grupo?.id,
            data: newValues,
            client: client
        })
            .then(() => {
                toastNotify(toast, StatusEnumTypes.SUCCESS, `FUNDAE se ha actualizado correctamente`)

                setTimeout(() => Refresh(), 100)
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    const changeDiploma = () => {
        if (
            !grupo?.id ||
            switchDiploma === null ||
            switchDiploma === undefined
        ) return

        updateValue({ permitirDescargaDiploma: !switchDiploma })
    }

    const handleSubmit = (supervisorId: string) => {
        toast({
            title: "Solicitud enviada",
            description: `Se ha enviado la solicitud para ${numMatriculas} ${numMatriculas === 1 ? "matrícula" : "matrículas"}`,
            status: "success",
            duration: 3000,
        })
        sendMailPrematriculas({
            id: grupo?.id || "",
            client: client,
            userId: supervisorId,
            participantes: numMatriculas,
            email: supervisores?.data?.find((s: any) => s.id === supervisorId)?.email || "",
            status: "pending"
        })
        onCloseNewSolicitudPrematriculas()
    }

    return (

        loading ?
            <GruposConfigSkeleton moduloFUNDAE={moduloFUNDAE}
                isSupervisor={isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) ? true : false} />
            :

            <Flex
                gap="20px"
            >
                <Flex direction="column" flex="1">
                    <InformationBox
                        title="Datos del grupo"
                        component={
                            <Flex
                                gap="20px"
                                alignItems="center"
                            >
                                {(grupo?.tareasGeneradas !== undefined && grupo?.tareasGeneradas !== null)
                                    &&
                                    <Flex
                                        alignItems="center"
                                        gap="10px"
                                    >
                                        <Text
                                            color="font"
                                            fontSize="14px"
                                            fontWeight="600"
                                        >
                                            Tareas generadas:
                                        </Text>

                                        <Tooltip
                                            label="Las tareas se generan automáticamente cuando se completa la información relativa a FUNDAE"
                                            placement="bottom"
                                            hasArrow
                                            textAlign="center"
                                            px="10px"
                                            py="5px"
                                            rounded="8px"
                                            bg="pureblack"
                                            color="purewhite"
                                            isDisabled={grupo?.tareasGeneradas || !grupo?.fundae}
                                        >
                                            <Badge
                                                color={!grupo?.tareasGeneradas ? "pureblack" : "success_main"}
                                                bg={!grupo?.tareasGeneradas ? "#EEEDFA" : "success_bg"}
                                                borderColor={!grupo?.tareasGeneradas ? "#EEEDFA" : "success_variant"}
                                                fontSize="10px"
                                                fontWeight="500"
                                                letterSpacing="0.2px"
                                                textTransform="uppercase"
                                                borderRadius="14px"
                                                borderWidth="1px"
                                                p="5px 8px"
                                            >
                                                {grupo?.tareasGeneradas ? "Sí" : "Pendiente"}
                                            </Badge>
                                        </Tooltip>
                                    </Flex>
                                }

                                {
                                    grupo?.estado &&
                                    <Flex
                                        alignItems="center"
                                        gap="10px"
                                    >
                                        <Text
                                            color="font"
                                            fontSize="14px"
                                            fontWeight="600"
                                        >
                                            Estado:
                                        </Text>

                                        <Badge
                                            color={
                                                grupo?.estado == "2" ? "font" :
                                                    grupo?.estado == "1" ? "secondary"
                                                        : "success_main"
                                            }
                                            bg={
                                                grupo?.estado == "2" ? "light_grey" :
                                                    grupo?.estado == "1" ? "#EEEDFA"
                                                        : "success_bg"
                                            }
                                            borderColor={
                                                grupo?.estado == "2" ? "light_grey" :
                                                    grupo?.estado == "1" ? "#EEEDFA"
                                                        : "success_variant"
                                            }
                                            fontSize="10px"
                                            fontWeight="500"
                                            letterSpacing="0.2px"
                                            textTransform="uppercase"
                                            borderRadius="14px"
                                            borderWidth="1px"
                                            p="5px 8px"
                                        >
                                            {
                                                grupo?.estado == "2" ? "Inactivo" :
                                                    grupo?.estado == "1" ? "Próximo"
                                                        : "Activo"
                                            }
                                        </Badge>
                                    </Flex>
                                }
                            </Flex>
                        }
                    >
                        <Box>
                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditNombre(true)}
                                onMouseLeave={() => setEditNombre(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    isRequired={true}
                                    name="nombre"
                                    label="Denominación del grupo"
                                    defaultValue={grupo?.nombre}
                                    updateValue={updateValue}
                                    isDisabled={grupo?.fundae && !grupo?.gestionaEmpresa || contenido}
                                />

                                <Flex boxSize="20px">
                                    {(grupo?.fundae && !grupo?.gestionaEmpresa || contenido) && <Icon as={BiLock} boxSize="20px" />}
                                    {(editNombre && (!grupo?.fundae || grupo?.gestionaEmpresa && !contenido)) && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <Flex w="100%">
                                    <InformationInput
                                        onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                        name="nombre"
                                        label="Denominación"
                                        defaultValue={grupo?.curso?.nombre || ""}
                                        updateValue={updateValue}
                                        isDisabled={true}
                                    />
                                </Flex>

                                <Icon as={BiLock} boxSize="20px" />
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                onMouseEnter={() => setEditFechaInicio(true)}
                                onMouseLeave={() => setEditFechaInicio(false)}
                                gap="10px"
                            >
                                <InformationDateInput
                                    locale="es"
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="fechaInicio"
                                    label="Fecha Inicio"
                                    updateValue={updateValue}
                                    defaultValue={
                                        formatTimezone({
                                            date: grupo?.fechaInicio,
                                            timeZone
                                        })
                                    }
                                    ui={{
                                        labelStyles: {
                                            textTransform: "uppercase",
                                            fontSize: "12px",
                                            fontWeight: "600",
                                            letterSpacing: "0.24px"
                                        }
                                    }}
                                    isDisabled={grupo?.fundae && grupo?.estado === "0" || contenido}
                                />

                                <Flex boxSize="20px">
                                    {(grupo?.fundae && grupo?.estado === "0" || contenido) && <Icon as={BiLock} boxSize="20px" />}
                                    {(editFechaInicio && grupo?.estado !== "0" && !contenido) && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                onMouseEnter={() => setEditFechaFin(true)}
                                onMouseLeave={() => setEditFechaFin(false)}
                                gap="10px"
                            >
                                <InformationDateInput
                                    locale="es"
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="fechaFin"
                                    label="Fecha Fin"
                                    updateValue={updateValue}
                                    defaultValue={
                                        formatTimezone({
                                            date: grupo?.fechaFin,
                                            timeZone
                                        })
                                    }
                                    ui={{
                                        labelStyles: {
                                            textTransform: "uppercase",
                                            fontSize: "12px",
                                            fontWeight: "600",
                                            letterSpacing: "0.24px"
                                        }
                                    }}
                                    isDisabled={grupo?.fundae && grupo?.estado === "0" || contenido}
                                />
                                <Flex boxSize="20px">
                                    {(grupo?.fundae && grupo?.estado === "0" || contenido) && <Icon as={BiLock} boxSize="20px" />}
                                    {(editFechaFin && grupo?.estado !== "0" && !contenido) && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                onMouseEnter={() => setEditImporteMatricula(true)}
                                onMouseLeave={() => setEditImporteMatricula(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    name="importeMatricula"
                                    label="Importe matrícula"
                                    defaultValue={grupo?.importeMatricula}
                                    updateValue={updateValue}
                                    isPrice={true}
                                    isDisabled={grupo?.matriculas && grupo?.matriculas?.length > 0 || contenido}
                                />

                                <Flex boxSize="20px">
                                    {(grupo?.matriculas && grupo?.matriculas?.length > 0 || contenido) && <Icon as={BiLock} boxSize="20px" />}
                                    {(editImporteMatricula && !contenido &&
                                        (!grupo?.matriculas || grupo?.matriculas?.length === 0)) &&
                                        <Icon as={BiPencil} boxSize="20px" />
                                    }
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom={"1px solid"}
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationSelect
                                    name="empresaOrganizadoraId"
                                    label="Empresa factura"
                                    updateValue={updateValue}
                                    options={
                                        (empresas === undefined || empresas === null)
                                            ? []
                                            : [...empresas?.data?.map((empresa: EmpresasInt) => ({ value: empresa?.id, label: empresa?.nombre }))]
                                    }
                                    defaultValue={{ value: grupo?.empresaOrganizadoraId, label: grupo?.empresaOrganizadora?.nombre }}
                                    ui={{
                                        informationSelectStyles: informationSelectStyles,
                                        styleLabel: {
                                            textTransform: "uppercase",
                                            fontSize: "12px",
                                            fontWeight: "600",
                                            letterSpacing: "0.24px"
                                        }
                                    }}
                                    isDisabled={UserRolEnum.ADMIN === user?.role?.nombre ? false : true}
                                />

                                <Flex boxSize="20px">
                                    <Icon as={BiPencil} boxSize="20px" />
                                </Flex>
                            </Flex>

                            <Flex
                                p="20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="duracion"
                                    label="Horas"
                                    defaultValue={grupo?.curso?.duracion}
                                    updateValue={updateValue}
                                    isDisabled={true}
                                />

                                <Flex boxSize="20px">
                                    <Icon as={BiLock} boxSize="20px" />
                                </Flex>
                            </Flex>
                        </Box>
                    </InformationBox>

                    <Flex
                        display={grupo?.curso?.tutorizado ? "flex" : "none"}
                    >
                        <InformationBox
                            title="Datos del tutor"
                            component={
                                UserRolEnum.ADMIN === user?.role?.nombre ?
                                    <Flex
                                        pos="relative"
                                    >
                                        <CustomButton
                                            tooltip={{
                                                label: contenido
                                                    ? "No tienes permisos para esta acción"
                                                    : "Puede crear un nuevo tutor para añadir al grupo",
                                                hasArrow: true,
                                                isDisabled: false,
                                                placement: "bottom",
                                            }}
                                            button={{
                                                label: "Nuevo tutor",
                                                isDisabled: contenido,
                                                onClick: onOpenTutor,
                                                rightIcon: {
                                                    as: BiPlusCircle,
                                                    boxSize: "20px",
                                                },
                                            }}
                                        />

                                        {(
                                            showStep &&
                                            grupo?.curso?.tutorizado &&
                                            (!user?.config?.matriculas && total) && grupo?.tutores?.length === 0)
                                            &&
                                            <OnboardingStep
                                                position={{
                                                    bottom: "23px",
                                                    left: "119px"
                                                }}
                                                title="Asigna un tutor al grupo"
                                                description="Crea y gestiona grupos,  asignando un tutor para los estudiantes del grupo y facilitar la organización, comunicación y seguimiento de los mismos."
                                                placement="bottom/left"
                                                onClick={() => setShowStep(false)}
                                            />
                                        }
                                    </Flex>
                                    :
                                    undefined
                            }
                        >
                            <Flex
                                pr="20px"
                                direction="column"
                                align="end"
                            >
                                <InformationAsyncCard
                                    data={grupo?.tutores}
                                    label="Tutores"
                                    placeholder="Añadir tutor"
                                    isRequired={true}
                                    loadOptions={loadTutores}
                                    name="tutores"
                                    removeItem={removeTutor}
                                    updateValue={updateValue}
                                    type="tutor"
                                    isDisabled={contenido}
                                    allowRemove={
                                        !grupo?.matriculas ||
                                            grupo?.matriculas?.length === 0 || !contenido ? true : false
                                    }
                                />
                            </Flex>
                        </InformationBox>
                    </Flex>
                </Flex>

                <Flex direction="column" flex="1">
                    <InformationBox
                        title="Empresa asignada"
                        isSwitch={true}
                        value={switchEmpresa}
                        setValue={setSwitchEmpresa}
                    >
                        <Box>
                            <Box pr="20px"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                            >
                                <InformationAsyncCard
                                    data={grupo?.empresas}
                                    label="Empresa"
                                    placeholder="Buscar empresa"
                                    isRequired={true}
                                    loadOptions={loadEmpresas}
                                    isDisabled={contenido}
                                    name="empresas"
                                    removeItem={removeEmpresa}
                                    updateValue={updateValue}
                                    type="empresa"
                                    allowRemove={!contenido}
                                />
                            </Box>

                            <Box pr="20px">
                                <AsyncCard
                                    label="Supervisor empresa"
                                    placeholder="Buscar supervisor"
                                    name="userId"
                                    id={grupo?.id}
                                    empresas={grupo?.empresas}
                                    data={grupo?.supervisores}
                                    setRefresh={Refresh}
                                    isDisabled={contenido}
                                />
                            </Box>

                            {(isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && moduloFUNDAE) &&
                                <Flex
                                    p="10px 20px"
                                    justifyContent="flex-end"
                                >
                                    <CustomButton
                                        tooltip={{
                                            label: contenido
                                                ? "No tienes permisos para esta acción"
                                                : "Si la empresa asociada no tiene supervisor, puedes añadir uno nuevo de aquí.",
                                            hasArrow: true,
                                            isDisabled: false,
                                            placement: "bottom",
                                        }}
                                        button={{
                                            label: "Nuevo supervisor",
                                            isDisabled: contenido,
                                            onClick: onOpenSupervisor,
                                            rightIcon: {
                                                as: BiPlusCircle,
                                                boxSize: "20px",
                                            },
                                        }}
                                    />
                                </Flex>}
                        </Box>
                    </InformationBox>

                    {(isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && moduloFUNDAE) &&
                        grupo?.curso?.tutorizado &&
                        <InformationBox
                            title="Solicitar prematrículas"
                            component={
                                <CustomButton
                                    button={{
                                        label: "Enviar solicitud",
                                        isDisabled: contenido,
                                        onClick: onOpenNewSolicitudPrematriculas,
                                        rightIcon: {
                                            as: BiEnvelopeOpen,
                                            boxSize: "20px",
                                        },
                                    }}
                                />
                            }
                            value={switchFormacion}
                            setValue={setSwitchFormacion}
                            onClick={changeFundae}
                            isDisabledSwitch={contenido}
                        />
                    }

                    {(isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && moduloFUNDAE) &&
                        grupo?.curso?.tutorizado &&
                        <InformationBox
                            title="FUNDAE"
                            isSwitch={true}
                            value={switchFormacion}
                            setValue={setSwitchFormacion}
                            onClick={changeFundae}
                            isDisabledSwitch={contenido}
                        />
                    }

                    {(isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) && moduloFUNDAE) &&
                        grupo?.curso?.tutorizado &&
                        <InformationBox title="Descarga de diplomas">
                            <Box
                                p="20px"
                            >
                                <SwitchItem
                                    label="Permitir descarga de diplomas aunque no supere el 75% tiempo de conexión"
                                    value={switchDiploma}
                                    setValue={setSwitchDiploma}
                                    onClick={changeDiploma}
                                    isDisabled={contenido}
                                />
                            </Box>
                        </InformationBox>
                    }

                    <InformationBox title="Descripción del grupo">
                        <Flex
                            p="10px 20px"
                            alignItems="center"
                            justifyContent="space-between"
                            w="100%"
                        >
                            <InformationTextarea
                                onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                name="descripcion"
                                placeholder="No hay descripción"
                                defaultValue={grupo?.descripcion || ""}
                                updateValue={updateValue}
                                isDisabled={contenido}
                            />

                        </Flex>
                    </InformationBox>
                </Flex>

                <NewSupervisorModal
                    isOpen={isOpenSupervisor}
                    onClose={onCloseSupervisor}
                    setRefreshTable={Refresh}
                    idEmpresa={((isEmpresaUnica && grupo?.empresas) && grupo?.empresas?.length > 0) ? grupo?.empresas[0]?.id : null}
                    idGrupo={grupo?.id}
                />

                <NewTutorModal
                    isOpen={isOpenTutor}
                    onClose={onCloseTutor}
                    setRefreshTable={Refresh}
                    updateGrupo={updateValue}
                    grupo={grupo}
                />

                <ModalFundae
                    isOpen={isOpenFundae}
                    onClose={onCloseFundae}
                    grupo={grupo}
                    Refresh={Refresh}
                    setSwitchFormacion={setSwitchFormacion}
                />

                <NewSolicitudPrematriculas
                    isOpen={isOpenNewSolicitudPrematriculas}
                    onClose={onCloseNewSolicitudPrematriculas}
                    numMatriculas={numMatriculas}
                    setNumMatriculas={setNumMatriculas}
                    handleSubmit={handleSubmit}
                    supervisores={supervisores}
                />
            </Flex >
    );
}