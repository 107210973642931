import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Box, Flex, Text, OrderedList, ListItem } from "@chakra-ui/react";
import { QuizData } from "../../../../interfaces/ExamenInt";
import { FaCheckCircle } from "react-icons/fa";

interface Props {
    exam: QuizData;
}


const ExamGenerate = ({ exam }: Props) => {

    const getAnswerQuestion = (preguntaId: number) => exam.respuestas.find((respuesta: any) => respuesta.id === preguntaId);

    return (
        <Flex
            align="center"
            direction="column"
        >
            <Text
                mb="20px"
                fontSize="22px"
                fontWeight="600"
                color="font"
            >
                Preguntas Generadas IA
            </Text>

            <Accordion
                allowToggle
                allowMultiple
                w="70vw"
            >
                {exam.preguntas.map((pregunta: any, index: number) => {
                    const respuesta = getAnswerQuestion(pregunta.id);

                    return (
                        <AccordionItem
                            key={pregunta.id}>
                            <Text
                                fontSize="16px"
                                color="font"
                            >
                                <AccordionButton>
                                    <Box
                                        as='span'
                                        flex='1'
                                        textAlign='left'
                                        padding='5px'
                                    >
                                        <Text
                                            fontSize="16px"
                                            color="font"
                                        >
                                            {index + 1}. {pregunta.texto}
                                        </Text>
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </Text>
                            <AccordionPanel pb={4}>
                                <OrderedList mb={4}>
                                    {pregunta.opciones.map((opcion: any, index: number) => (
                                        <ListItem
                                            key={index}
                                            fontSize="16px"
                                            color={respuesta && index === respuesta.respuestaCorrecta ? "green.500" : "font"}
                                            fontWeight={respuesta && index === respuesta.respuestaCorrecta ? "bold" : "normal"}
                                        >
                                            {respuesta && index === respuesta.respuestaCorrecta ? (
                                                <Flex align="center" gap="10px">
                                                    {opcion} <FaCheckCircle />
                                                </Flex>
                                            ) : (
                                                opcion
                                            )}
                                        </ListItem>
                                    ))}
                                </OrderedList>

                                {respuesta &&
                                    <Box mt={2}>
                                        <Text
                                            fontSize="18px"
                                            fontWeight="bold"
                                            py="5px"
                                            textAlign='center'
                                            color="font"
                                        >
                                            Explicación:
                                        </Text>
                                        <Text
                                            textAlign="center"
                                            border="1px solid"
                                            borderColor="main"
                                            color="font"
                                            borderRadius="5px"
                                            p="10px"
                                        >
                                            {respuesta.explicacion}
                                        </Text>
                                    </Box>
                                }

                            </AccordionPanel>
                        </AccordionItem>
                    );
                })}
            </Accordion>
        </Flex>
    )
}

export default ExamGenerate;
